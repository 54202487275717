<template>
  <div class="secondary">
    <v-container v-if="!this.$isMobile" style="padding: 150px 0">
      <v-row align="center" style="height: 100%">
        <v-col cols="7" align="left">
          <p class="accent--text text-h1 font-weight-bold outlined-text">Our Questions.</p>
          <p class="accent--text text-h1 font-weight-bold outlined-text">Your Friends.</p>
          <p class="text-h3">{{ subtitle }}</p>
          <v-container style="margin-top: 40px">
            <v-row>
              <router-link to="/join" class="inherit" style="margin-right: 60px"><v-btn class="primary" x-large>Join Game</v-btn></router-link>
              <router-link to="/create" class="inherit"><v-btn class="primary" x-large>Create Game</v-btn></router-link>
            </v-row>
          </v-container>
        </v-col>
        <v-col align="right" cols="5">
          <v-img  :src="require('../../assets/home/two-people.png')"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="this.$isMobile">
      <v-row style="margin: 40px 0">
        <v-col>
          <p class="accent--text text-h3 font-weight-bold outlined-text-mobile">Our Questions.</p>
          <p class="accent--text text-h3 font-weight-bold outlined-text-mobile">Your Friends.</p>
          <p class="text-h5">{{ subtitle }}</p>
        </v-col>
      </v-row>
      <v-row style="margin: 40px 0" justify="space-between">
        <v-col>
          <router-link to="/join" class="inherit"><v-btn class="primary" large>Join Game</v-btn></router-link>
        </v-col>
        <v-col>
          <router-link to="/create" class="inherit"><v-btn class="primary" large>Create Game</v-btn></router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!--<v-row align="center">
    <v-col>
      <p class="accent--text text-h3 font-weight-bold outlined-text-mobile">Our Questions.</p>
      <p class="accent--text text-h3 font-weight-bold outlined-text-mobile">Your Friends.</p>
      <p class="text-h5">Host the perfect trivia night (while we do all the work)</p>
      <v-container style="margin: 40px 0">
        <v-row justify="space-between">
          <router-link to="/join" class="inherit"><v-btn class="primary" large>Join Game</v-btn></router-link>
          <router-link to="/create" class="inherit"><v-btn class="primary" large>Create Game</v-btn></router-link>
        </v-row>
      </v-container>
    </v-col>
  </v-row>-->
</template>

<script>
export default {
  name: "HeroTile",
  data: () => {
    return {
      subtitle: "Host the perfect virtual trivia night (while we do all the work)"
    }
  }
}
</script>

<style scoped>

</style>