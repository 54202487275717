<template>
  <v-container :style="{height: (this.height * 8) + 'px', width: this.width + 'px'}">
    <v-row>
      <v-col>
        <div class="question-card">
          <QuestionCard :question="getQuestion(0)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="1" header="BEGINNER #1"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(1)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="2" header="EASY #1"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(2)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="3" header="EASY #2"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(3)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="4" header="MEDIUM #1"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(4)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="5" header="MEDIUM #2"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(5)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="6" header="MEDIUM #3"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(6)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="7" header="HARD #1"></QuestionCard>
        </div>
        <div class="question-card">
          <QuestionCard :question="getQuestion(7)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="sampleRound.title" :number="8" header="HARD #2"></QuestionCard>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuestionCard from './QuestionCard'

export default {
  name: "CategorySummaryCard",
  components: {QuestionCard},
  props: ["height", "width"],
  data() {
    return {
      shouldFlip: true,
      sampleRound: {
        "_id":"3faf6e13-387d-441c-82ca-669b3fb3e664",
        "title":"We \"Can\" Do It",
        "subtitle":"Grab-bag of questions about concepts starting with the letters \"CAN\"",
        "published":true,
        "questions":[
            // Beginner
          {"_id":"be09dde0-4722-49fb-b2d1-c12bc7df1a4f","genre":"Geography","question":"Venice is the best-known city of canals, but there are many others with similar networks, like Bangkok and what other city, where the canals are known as \"grachten\"?","quote":"","answers":["Amsterdam"],"fact":"","url":""},
            // Easy
          {"_id":"f9571671-0a20-4c20-81a8-c97d9c5eec3f","genre":"Movies","question":"The Cannes film festival was first held in 1946, when it awarded the aptly named Grand Prix to eleven different films. Starting in 1955, the top award at the festival was given to only one film per year, and was renamed what?","quote":"","answers":["The Palme D'or (Golden palm)"],"fact":"","url":""},
          {"_id":"9673ef64-4b2a-48c6-a7ff-24761a94fa73","genre":"Science","question":"Canis Major (\"great dog\") is one of the best known constellations, largely due to its inclusion of what star (also known as \"the dog star\"), which is the brightest in the sky?","quote":"","answers":["Sirius"],"fact":"This star is the namesake of Harry Potter's Sirius Black, who turns into a dog.","url":""},

            // Medium
          {"_id":"fea6a17b-ce3b-468b-8f6e-bbf507dee0e8","genre":"Geography","question":"Though \"Canaan\" once referred to a specific civilization that existed in the 2nd millennium BCE, the word was generally used in the Bible to refer to the region encompassing the modern day countries of Syria, Lebanon, Jordan, Israel, and Palestine. By what French word is this region better known today?","quote":"","answers":["The Levant"],"fact":"Levant means \"rising\" in French, referencing the East as the direction the sun rises.","url":""},
          {"_id":"3aa1d908-01fc-49b0-b9e1-ac25795ab729","genre":"Music","question":"Although the 1812 Overture is frequently played in the US on Independence Day, it (and its climactic cannon fire), has nothing to do with our War of 1812, but instead celebrates the successful defense of the composer's country against Napoleon. Who is this composer?","quote":"","answers":["Pyotr Tchaikovsky"],"fact":"Written in 1880, it celebrates Russia's defense against Napoleon's invasion earlier that century.","url":""},
          {"_id":"732b7503-e054-4e80-91aa-90fba74e3663","genre":"Math","question":"Voltaire's *Candide* is a satire of many things, but principally skewers the optimistic views of what German philosopher (and calculus inventor), who believed that despite its problems, we live in \"the best of all possible worlds\"?","quote":"","answers":["Gottfried Leibniz"],"fact":"The stand-in for Leibniz in the book, Dr. Pangloss, lends his name to an alternate word for this philosophy: Panglossianism.","url":""},

            // Hard
          {"_id":"5a3fda98-9b8a-4cc4-8c94-24ba7779c6ae","genre":"Art","question":"The can-can started out as a dance for couples, but quickly became more suggestive when performed by women in the cabarets of 1840s France. What artist, known for his depictions of the Moulin Rouge, painted the poster seen here?","quote":"","answers":["Henri Toulouse-Lautrec"],"fact":"","url":"2-2-2022/cancan.jpeg"},
          {"_id":"3b743f81-6db5-4623-ae7a-8861814c3273","genre":"Geography","question":"Canton (derived from an alternate pronunciation of the city's province), was the official English name of what Chinese city up until the late 20th century? Its namesake Cantonese language isn't widely spoken outside the region, but as the primary language in nearby Hong Kong and Macau, it is still important globally.","quote":"","answers":["Guangzhou"],"fact":"The city had long been China's third largest behind Shanghai and Beijing, but in recent years has been passed by faster-growing cities like Tianjin and Chongqing.","url":""},
        ],
        "difficulty":"EASY",
        "tags":["variety"]
      }
    }
  },
  computed: {
    calculateTitleSize() {
      return Math.sqrt(this.height * this.width / this.title?.length) * 0.4;
    },
    calculateCellWidth() {
      return this.width - 30;
    },
    calculateCellHeight() {
      return this.height - 30;
    }
  },
  methods: {
    getQuestion: function (i) {
      return this.sampleRound?.questions[i];
    }
  }
}
</script>

<style scoped>
.question-card {
  margin: 10px 0;
  border: black solid;
  padding: 10px;
}
</style>