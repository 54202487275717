<template>
  <div :class="flipped ? 'flip-container flipped' : 'flip-container'">
    <div class="flipper">
      <div class="front" v-on:click="requestFlip()">
        <v-container fluid>
          <v-row class="primary--text" justify="space-between" :style="{ fontSize: calculateHeaderSize + 'px'}" style="margin-bottom: 15px;">
            <span>{{ headerText }}</span>
<!--            <span v-if="seconds">{{ timeRemaining }}</span>-->
          </v-row>
          <div v-if="showQuestionOnly" id="tmp-question-only">
            <v-row :style="{ fontSize: calculateFontSize + 'px', height: areaHeight + 'px'}" v-html="questionText"></v-row>
          </div>
          <div v-else-if="showImageOnly" id="tmp-image-only">
            <v-row :style="{ height: areaHeight + 'px' }">
              <v-img contain :src="questionImageUrl" v-bind:height="imageHeight + 'px'" v-bind:width="imageWidth + 'px'"></v-img>
            </v-row>
          </div>
          <div v-else-if="showQuestionAndImageH" id="tmp-question-and-image-h">
            <v-row :style="{height: (areaHeight / 2) + 'px', fontSize: calculateFontSize + 'px'}" v-html="questionText"></v-row>
            <v-row :style="{height: (areaHeight / 2) + 'px'}" justify="center">
              <v-img contain v-if="!hasVideo" :src="questionImageUrl" v-bind:height="imageHeight + 'px'" v-bind:width="imageWidth + 'px'"></v-img>
              <VideoPlayer :maxwidth="width" :maxheight="areaHeight / 2" v-if="hasVideo" :options="videoOptions" />
            </v-row>
          </div>
          <div v-else-if="showQuestionAndImageV" id="tmp-question-and-image-v">
            <v-row :style="{ height: areaHeight + 'px'}">
              <v-col cols="6" :style="{ fontSize: calculateFontSize + 'px'}" v-html="questionText" style="padding: 0"></v-col>
              <v-col cols="6"><v-img :src="questionImageUrl" contain :max-width="width / 2" :max-height="areaHeight"></v-img></v-col>
            </v-row>
          </div>
          <div v-else-if="showQuestionAndQuote" id="tmp-question-and-quote">
            <v-row :style="{ fontSize: calculateFontSize + 'px', height: (areaHeight / 2) + 'px'}" v-html="questionText"></v-row>
            <v-row :style="{ fontSize: calculateQuoteSize + 'px', height: (areaHeight / 2) + 'px', fontStyle: 'italic'}" v-html="questionQuote"></v-row>
          </div>
        </v-container>
      </div>
      <div class="back" v-on:click="requestFlip()">
        <v-container fluid>
          <v-row :style="{ fontSize: calculateHeaderSize + 'px'}">{{ headerText }}</v-row>
          <v-row justify="center" align="center" v-html="answerText" :style="{ height: ((this.question.answerUrl) ? (areaHeight/2) : areaHeight) + 'px', fontSize: calculateAnswerSize + 'px', color: 'green' }">
          </v-row>
          <!--<v-row v-if="this.question.fact" :style="{ height: (areaHeight / 2) + 'px', fontSize: calculateFactSize + 'px', color: 'green' }" >
            <v-col align="left"><span class="font-weight-bold">Additional fact: </span>
            {{ this.question.fact }}
            </v-col>
          </v-row>-->
          <v-row v-if="this.question.answerUrl" :style="{ height: (areaHeight / 2) + 'px' }" >
              <v-img contain :src="answerImageUrl" v-bind:height="(areaHeight / 2) + 'px'" v-bind:width="imageWidth + 'px'"></v-img>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer";
export default {
  name: "QuestionCard",
  components: {VideoPlayer},
  props: ["question", "height", "width", "category", "number", "flippable", "header", "seconds"],
  data() {
    return {
      imageAspectRatio: 1,
      flipped: false,
      timeLeft: this.seconds,
      timerInterval: null
    }
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        fluid: true,
        controls: true,
        sources: [
          {
            src: this.questionImageUrl,
            type: this.questionImageUrl.includes('.mp4') ? "video/mp4" : "audio/mp3"
          }
        ]
      }
    },
    hasVideo() {
      return this.questionImageUrl.includes('.mp4') || this.questionImageUrl.includes('.mp3')
    },
    timeRemaining() {
      const minutes = Math.floor(this.timeLeft / 60)
      let seconds = this.timeLeft % 60
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      // The output in MM:SS format
      return `${minutes}:${seconds}`
    },
    headerText() {
      return this.header ? this.header : this.category + " - Question " + this.number;
    },
    questionText() {
      let text = this.question.question.replaceAll(/\*(.*?)\*/g, '<span class="font-italic regular-span">$1</span>');
      text = text.replaceAll(/#(.*?)#/g, '<span class="font-weight-bold regular-span">$1</span>');
      return `<span>${text}</span>`;
    },
    answerText() {
      let text = this.question.answers.join(', ').replaceAll(/\*(.*?)\*/g, '<span class="font-italic regular-span">$1</span>');
      text = text.replaceAll(/#(.*?)#/g, '<span class="font-weight-bold regular-span">$1</span>');
      return `<span>${text}</span>`;
    },
    questionImageUrl() {
      return this.question?.url ? `${this.$apiEndpoint()}/assets/${this.question.url}` : '';
    },
    answerImageUrl() {
      return this.question?.answerUrl ? `${this.$apiEndpoint()}/assets/${this.question.answerUrl}` : '';
    },
    questionQuote() {
      return this.question.quote.replaceAll('\n', '<br/>');
    },
    showQuestionOnly() {
      return this.questionImageUrl === "" && this.questionQuote === "";
    },
    areaHeight() {
      return this.height - this.calculateHeaderSize * 1.5;
    },
    showQuestionAndImageV() {
      if (this.questionImageUrl === "") {
        return false;
      } else {
        return this.imageAspectRatio < 1;
      }
    },
    showImageOnly() {
      if (this.question.question === "" && this.questionImageUrl !== "") {
        return true;
      }
      return false;
    },
    showQuestionAndImageH() {
      if (this.questionImageUrl === "") {
        return false;
      } else {
        return this.imageAspectRatio >= 1;
      }
    },
    showQuestionAndQuote() {
      return this.questionQuote !== "";
    },
    imageHeight() {
      return this.showImageOnly ? this.areaHeight : this.areaHeight / 2;
    },
    imageWidth() {
      return this.imageHeight * this.imageAspectRatio;
    },
    calculateHeaderSize() {
      return Math.sqrt(this.height * this.width) / 30;
    },
    calculateFontSize() {
      if (this.showQuestionOnly) {
        return Math.sqrt(this.height * this.width / this.questionText.length);
      }

      return Math.sqrt(this.height * this.width / this.questionText.length / 2) * 0.75;
    },
    calculateQuoteSize() {
      return Math.sqrt(this.height * this.width / this.questionQuote.length / 2) * 0.75;
    },
    calculateAnswerSize() {
      if (this.question.fact || this.question.answerUrl) {
        return Math.sqrt(this.height * this.width / this.question.answers.join(", ").length / 2) * 0.4;
      }

      return Math.sqrt(this.height * this.width / this.question.answers.join(", ").length) * 0.4;
    },
    calculateFactSize() {
      return Math.sqrt(this.height * this.width / this.question.fact.length / 2) * 0.75;
    }
  },
  methods: {
    calculateOrientation() {
      if (this.questionImageUrl.includes('.mp4')) {
        this.imageAspectRatio = 1.2;
        return;
      }

      let img = new Image();
      let _this = this;
      img.src = this.questionImageUrl;
      img.onload = function() {
        _this.imageAspectRatio = this.width / this.height;
      }
    },
    requestFlip() {
      if (this.flippable) {
        this.flipped = !this.flipped;
      }
    }
  },
  mounted() {
    this.calculateOrientation();
    this.timerInterval = setInterval(() => {
      this.timeLeft -= 1;
      if (this.timeLeft === 0) {
        clearInterval(this.timerInterval)
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style scoped>
/* Flip logic from https://github.com/andersponders/vue-flip-card/blob/master/FlipCard.vue */
.flip-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>