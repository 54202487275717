<template>
 <div :style="{width: maxWidth, height: maxHeight}">
   <video ref="videoPlayer" class="video-js" ></video>
 </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    maxwidth: {
      type: Number,
      default() {
        return 300;
      }
    },
    maxheight: {
      type: Number,
      default() {
        return 150;
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      defaultRatio: (16/9)
    }
  },
  computed: {
    maxHeight() {
      return Math.min(this.maxheight, this.maxwidth / this.defaultRatio) + 'px';
    },
    maxWidth() {
      return Math.min(this.maxwidth, this.maxheight * this.defaultRatio) + 'px';
    }
  },
  mounted() {
    this.Video().then(videojs => {
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      });
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style scoped>

</style>