<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col :cols="$isMobile ? 12 : 6" v-if="!submitted">
        <v-form ref="form" v-model="valid" lazy-validation >
          <v-text-field v-model="email" label="Email" required :rules="emailRules" id="email-field"></v-text-field>
          <v-btn class="primary" @click="submitEmail">Sign up</v-btn>
        </v-form>
      </v-col>
      <v-col cols="$isMobile ? 12 : 6" v-if="submitted">Thanks! We'll let you know when the next game is happening</v-col>
      <v-spacer/>
      <!--       <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=TriviaNight&dates=20220331T010000Z/20220331T030000Z&details=Head%20over%20to%20www.trivianight.fun/open%20on%20the%20day%20of%20to%20participate!&location=www.TriviaNight.fun/open&trp=true" class="inherit cal-btn"><v-btn color="primary" large @click="saEvent('google_calendar')">Add to Google Calendar</v-btn></a>-->
      <!--       <a href="/open.ics" class="inherit cal-btn"><v-btn color="primary" large @click="saEvent('icalendar')">Add to iCalendar</v-btn></a>-->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MailingListSignup",
  data: () => {
    return {
      submitted: false,
      valid: true,
      email: null,
      emailRules: [
        v => !!v || 'Email is required',
        v => (v || '').includes('@') || 'A valid email address is required'
      ]
    }
  },
  methods: {
    submitEmail() {
      if (this.$refs.form.validate()) {
        this.$post(`${this.$apiEndpoint()}/emaillist`, { email: this.email }).then(() => {
          this.submitted = true;
        });
      }
    }
  },
  mounted() {
    if (localStorage.getItem("token")) {
      let jwt = this.$decodeJwt(localStorage.getItem("token"));
      this.email = jwt.email;
      this.submitted = jwt.emaillist;
    }
  }
}
</script>

<style scoped>

</style>