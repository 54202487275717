<template>
  <v-card height="350" min-width="350" max-width="400" class="game-card d-flex flex-column" align="left">
    <v-card-title>{{ game.name }}</v-card-title>
    <v-card-subtitle>
      <v-row dense>
        <v-col>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" plain small style="margin-left: -10px; text-transform: none" @click="copyGameCode">{{ `Game Code: ${game.id}`}}</v-btn>
            </template>
            <span>{{ (game.gameType === 'DIY') ? 'Copy Game Link' : 'Copy Game Code'}}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text style="overflow-y: scroll" class="hide-scrollbar">
      <v-row>
        <v-col>
          <div v-for="(cat, i) in game.categoryDetails" :key="i" class="text-body-1">
            {{ `${cat.title}`}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer/>
    <v-card-actions class="justify-space-around" style="padding-bottom: 52px;" v-if="game.gameType === 'HOSTED'">
      <v-btn color="primary" v-bind="{outlined: Boolean(game.calendlyUri) }" @click="scheduleHosting">{{ game.calendlyUri ? "Reschedule" : "Schedule" }}</v-btn>
    </v-card-actions>
    <v-card-actions class="justify-space-around" v-else-if="game.gameType === 'VIRTUAL' && !game.ohyayUrl">
      <v-btn color="primary" @click="createVirtualPub">Create Virtual Pub</v-btn>
    </v-card-actions>
    <v-card-actions class="justify-space-around" v-else>
      <v-btn v-if="game.gameType === 'VIRTUAL'" color="primary" outlined style="margin: 0 10px" @click="share">Invite</v-btn>
      <router-link style="text-decoration: inherit" :to="{ name: 'Present Game', params: { id: `${game.id}` }}"><v-btn color="primary" outlined style="margin: 0 10px">Present</v-btn></router-link>
      <router-link style="text-decoration: inherit" :to="{ name: 'Manage Game', params: { id: `${game.id}` }}"><v-btn color="primary" outlined style="margin: 0 10px">Manage</v-btn></router-link>
    </v-card-actions>
    <v-card-actions v-if="game.gameType !== 'HOSTED'" style="padding-bottom: 15px;" class="justify-center">
      <a class="accent--text text-caption" href="#" @click="reset">Reset Game</a>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "GameCard",
  props: ["game"],
  data: () => {
    return {
    }
  },
  methods: {
    scheduleHosting() {
      this.$emit('scheduleHosting');
    },
    copyGameCode() {
      this.$emit('copyGameCode');
    },
    copyOhyayUrl() {
      this.$emit('copyOhyayUrl');
    },
    createVirtualPub() {
      this.$emit('createVirtualPub');
    },
    share() {
      this.$emit('share');
    },
    reset() {
      this.$emit('reset');
    }
  }
}
</script>

<style scoped>
.game-card {
  margin: 20px 0;
}
/* TODO: I probably shouldn't remove this entirely */
.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0 !important;;
}
</style>