<template>
  <v-container style="margin-top: 50px">
    <div v-if="congrats">
      <v-row class="text-h3 primary--text"><v-col>Congratulations!</v-col></v-row>
      <v-row class="text-h4">
        <v-col>
          Now that you've created a game, you're ready to host. Each game has two important components:
        </v-col>
      </v-row>
      <v-row class="text-h5">
        <v-col style="margin-left: 15px">
          1. The "Present" page - This is the page you'll display to your trivia contestants.
        </v-col>
      </v-row>
      <v-row class="text-h5">
        <v-col style="margin-left: 15px">
          2. The "Manage" page - This page will be used behind the scenes (on a phone or in a separate window) to see the answers to questions and to score the game.
        </v-col>
      </v-row>
    </div>
    <v-row v-if="games && games.length > 0" class="text-h4" style="margin: 50px 0">
      <v-col>
        For detailed hosting instructions, check out the <router-link to="/faq">FAQ</router-link> page at any time.
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="300">
      <v-card>
        <v-card-title>Reset Game?</v-card-title>
        <v-card-text>Are you sure you want to reset this game? Resetting will delete all answers submitted by players and will set the active category back to the first round.</v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" outlined @click="dialog = false">Don't Reset</v-btn>
          <v-btn color="red" outlined @click="reset()">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="share" max-width="800">
      <v-card>
        <v-card-title class="justify-space-between">
          <span>Invite</span>
          <v-btn text @click="share = false">Close</v-btn>
        </v-card-title>
        <v-card-subtitle>
          Copy/paste this invitation to everyone who will play your game.
        </v-card-subtitle>
        <v-card-text>
          <v-textarea :value="shareText" rows="14"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ohyay" max-width="600">
      <v-card>
        <v-card-title class="justify-space-between">
          <span>Create Virtual Pub</span>
          <v-btn text @click="ohyay = false">Close</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
            In order to set up your virtual pub, you'll need to make an account with Ohyay, our video partner. Head on over there to create an account, then come back and finish the process:
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center">
              <a href="https://ohyay.co/signin.html" target="_blank"><v-btn class="primary">Create Ohyay account</v-btn></a>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Once you've done that, enter the email you used for your Ohyay account:</v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-form ref="ohyayForm">
                <v-text-field label="Ohyay Account Email" outlined v-model="ohyayEmail" :rules="ohyayEmailRules" required></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-alert dense text outlined type="warning" color="primary">Make sure you finished creating your Ohyay account before hitting "Create"!</v-alert>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding-bottom: 20px" class="justify-center">
          <v-btn color="accent" @click="createOhyay">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="copiedSnackbar" :timeout="2000" color="accent">Copied!</v-snackbar>
    <v-snackbar v-model="resetSnackbar" :timeout="2000" color="accent">Successfully reset!</v-snackbar>
    <v-row v-if="loading" justify="center">
      <v-skeleton-loader min-width="300" type="card" style="margin: 10px"></v-skeleton-loader>
      <v-skeleton-loader min-width="300" type="card" style="margin: 10px"></v-skeleton-loader>
      <v-skeleton-loader min-width="300" type="card" style="margin: 10px"></v-skeleton-loader>
    </v-row>
    <v-row v-else-if="games && games.length > 0" justify="center">
      <v-col v-for="(game, i) in games" :key="i" align="center">
        <GameCard :game="game" v-on:scheduleHosting="scheduleHosting(game)" v-on:copyGameCode="copyGameCode(game)" v-on:copyOhyayUrl="copyOhyayUrl(game)" v-on:share="openShare(game)" v-on:createVirtualPub="createVirtualPub(game)" v-on:reset="resetId = game.id; dialog = true"/>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-h4" align="center">
        Looks like you haven't created any games yet. <router-link to="/create">Make one now!</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GameCard from "../components/manage/GameCard";
export default {
  name: "Manage",
  metaInfo: {
    title: 'Manage'
  },
  components: {GameCard},
  data: () => ({
    games: null,
    congrats: false,
    copiedSnackbar: false,
    resetSnackbar: false,
    dialog: false,
    resetId: '',
    ohyay: false,
    ohyayGame: null,
    ohyayEmail: null,
    ohyayEmailRules: [
      v => !!v || 'Email required',
       v => !!v && v.includes('@') || 'Enter a valid email'
    ],
    share: false,
    shareInstructions: 'Join me for a fun game of trivia! When it\'s time to play, click this link to join the virtual pub: \n\n##url## \n\nThere\'s no need to do anything to prepare in advance (except for brush up on your trivia skills!), but if you want to know exactly how things will work, head here:\n\nhttps://www.trivianight.fun/ohyay\n\nSee you soon!',
    shareText: '',
    loading: true,
  }),
  methods: {
    copyGameCode(game) {
      if (game.gameType === 'DIY') {
        navigator.clipboard.writeText(window.location.origin + "/join/" + game.id);
      } else {
        navigator.clipboard.writeText(game.id);
      }
      this.copiedSnackbar = true;
    },
    copyOhyayUrl(game) {
      navigator.clipboard.writeText(game.ohyayUrl);
      this.copiedSnackbar = true;
    },
    openShare(game) {
      this.shareText = this.shareInstructions.replace('##url##', game.ohyayUrl);
      this.share = true;
    },
    createVirtualPub(game) {
      this.ohyayGame = game;
      this.ohyay = true;
    },
    createOhyay() {
      if (this.$refs.ohyayForm.validate()) {
        this.$post(`${this.$apiEndpoint()}/ohyay`, {
          gameId: this.ohyayGame.id,
          email: this.ohyayEmail
        }).then(response => {
          if (response instanceof Error) {
            return;
          }

          this.$router.go();
        });
      }
    },
    reset() {
      if (this.resetId.length > 0) {
        this.$post(`${this.$apiEndpoint()}/reset/${this.resetId}`, {}).then(response => {
          if (response instanceof Error) {
            return;
          }

          this.dialog = false;
          this.resetSnackbar = true;
        });
      }
    },
    scheduleHosting(game) {
      let listener = (e) => {
        if (e.data.event === 'calendly.event_scheduled') {
          window.removeEventListener('message', listener);
          let uri = e.data.payload.event.uri;
          this.$post(`${this.$apiEndpoint()}/schedule/${game.id}`, {
            uri: uri
          }).then(response => {
            if (response instanceof Error) {
              return;
            }

            game.calendlyUri = uri;
          });
        }
      };
      window.addEventListener('message', listener);

      let jwt = this.$decodeJwt();
      let url = game.calendlyUri ? game.calendlyUri : 'https://calendly.com/trivianight/trivia-' + game.categories.length + '-rounds'
      this.Calendly().then(calendly => {
        calendly.initPopupWidget({
          url: url,
          prefill: {
            email: jwt?.email ? jwt.email : ""
          }
        });
      });
    }
  },
  mounted() {
    this.congrats = this.$route?.params?.congrats
    this.$get(`${this.$apiEndpoint()}/manage`, {}).then(response => {
      if (response instanceof Error) {
        return;
      }

      this.loading = false;
      this.games = response.data;
    });
  }
}
</script>

<style scoped>

</style>