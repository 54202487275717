<template>
  <div>
    <v-banner color="accent_two" v-bind="{singleLine: !this.$isMobile}">
      <v-row justify="center">
        <v-col align="center" class="text-body-1">
          Check out our <router-link to="/samples">sample questions</router-link>!
        </v-col>
      </v-row>
    </v-banner>
    <v-container style="margin-top: 50px">
      <v-row v-if="this.$isMobile">
        <v-col>
          <CartCard ref="cart" v-on:checkout="checkout"></CartCard>
          <v-tabs v-model="tab" show-arrows>
            <v-tab href="#categories">Individual Categories</v-tab>
            <v-tab href="#category-sets">Category Sets</v-tab>
          </v-tabs>
          <v-container>
            <v-tabs-items v-model="tab" style="padding-bottom: 100px">
              <v-tab-item value="categories" class="tab-item">
                <v-row>
                  <v-text-field v-model="filterText" clearable outlined hide-details label="Search" style="padding: 0 20px;" :append-icon="icons.mdiMagnify" class="iconify" @click:clear="filterText = ''"></v-text-field>
                </v-row>
                <v-row class="font-weight-bold" style="padding: 10px">
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Filters</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-chip class="filter-chip" small v-for="filter in filters" :key="filter" @click="filterText = filter">{{ filter }}</v-chip>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
                <v-skeleton-loader class="mx-auto" max-width="300" type="card" v-if="!this.categories"></v-skeleton-loader>
                <CategoryCard v-for="(category, i) in filteredCategories" :key="i" :category="category" v-on:addToCart="$refs.cart.addToCart(category)" v-on:filter="filterText = $event"></CategoryCard>
              </v-tab-item>
              <v-tab-item value="category-sets" class="tab-item">
                <v-row>
                  <v-text-field v-model="filterText" clearable outlined label="Search" style="padding: 0 20px;" :append-icon="icons.mdiMagnify" @click:clear="filterText = ''"></v-text-field>
                </v-row>
                <v-skeleton-loader class="mx-auto" max-width="300" type="card" v-if="!this.categories"></v-skeleton-loader>
                <CategorySetCard v-for="(set, i) in filteredCategorySets" :key="i" :set="set" v-on:addToCart="$refs.cart.addSetToCart(set)"></CategorySetCard>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-if="!this.$isMobile">
        <v-col class="overflow-y-auto hide-scrollbar" >
          <v-tabs v-model="tab" grow>
            <v-tab href="#categories">Individual Categories</v-tab>
            <v-tab href="#category-sets">Category Sets</v-tab>
          </v-tabs>
          <v-container :style="{maxHeight: 'calc(100vh - 64px)'}">
            <v-tabs-items v-model="tab">
              <v-tab-item value="categories" class="tab-item">
                <v-row>
                  <v-text-field v-model="filterText" clearable outlined hide-details label="Search" style="padding: 0 20px;" :append-icon="icons.mdiMagnify" @click:clear="filterText = ''"></v-text-field>
                </v-row>
                <v-row class="font-weight-bold" style="padding: 10px">
                  <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Filters</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-chip class="filter-chip" small v-for="filter in filters" :key="filter" @click="filterText = filter">{{ filter }}</v-chip>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
                <v-skeleton-loader class="mx-auto" type="card" v-if="!this.categories"></v-skeleton-loader>
                <CategoryCard v-for="(category, i) in filteredCategories" :key="i" :category="category" v-on:addToCart="$refs.cart.addToCart(category)" v-on:filter="filterText = $event"></CategoryCard>
              </v-tab-item>
              <v-tab-item value="category-sets" class="tab-item">
                <v-row>
                  <v-text-field v-model="filterText" clearable outlined label="Search" style="padding: 0 20px;" :append-icon="icons.mdiMagnify" @click:clear="filterText = ''"></v-text-field>
                </v-row>
                <v-skeleton-loader class="mx-auto" type="card" v-if="!this.categories"></v-skeleton-loader>
                <CategorySetCard v-for="(set, i) in filteredCategorySets" :key="i" :set="set" v-on:addToCart="$refs.cart.addSetToCart(set)"></CategorySetCard>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <CartCard ref="cart" style="margin-top: 76px" v-on:checkout="checkout"></CartCard>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CartCard from "../components/create/CartCard";
import CategoryCard from "../components/create/CategoryCard";
import CategorySetCard from "../components/create/CategorySetCard";
import {mdiMagnify} from '@mdi/js'
export default {
  name: "CreateGame",
  metaInfo: {
    title: 'Create Game'
  },
  components: {CategorySetCard, CategoryCard, CartCard},
  data () {
    return {
      tab: null,
      categories: null,
      categorySets: null,
      filterText: '',
      filters: ["beginner", "easy", "medium", "hard", "stumper", "niche", "variety", "food", "mystery", "sports", "art", "geography", "history", "movies", "science", "literature", "video games", "fashion", "language"],
      icons: {
        mdiMagnify
      }
    }
  },
  computed: {
    filteredCategories() {
      if (this.filterText === '') {
        return this.categories;
      }

      return this.categories?.filter(
          cat => cat.title.toLowerCase().includes(this.filterText.toLowerCase()) ||
              cat.subtitle.toLowerCase().includes(this.filterText.toLowerCase()) ||
              cat.difficulty.toLowerCase().includes(this.filterText.toLowerCase()) ||
              cat.tags.includes(this.filterText.toLowerCase())
      );
    },
    filteredCategorySets() {
      if (this.filterText === '') {
        return this.categorySets;
      }

      return this.categorySets?.filter(set => set.name.toLowerCase().includes(this.filterText.toLowerCase()) || set.categoryDetails.filter(cat => cat.title.toLowerCase().includes(this.filterText.toLowerCase())).length > 0)
    },
  },
  methods: {
    checkout(data) {
      this.$post(`${this.$apiEndpoint()}/checkout`, data).then(response => {
        if (response instanceof Error) {
          return;
        }

        window.location.href = response.data;
      });
    },
  },
  mounted() {
    if (this.$route.query.sessionId) {
      this.$post(`${this.$apiEndpoint()}/activate`, {
        checkoutSessionId: this.$route.query.sessionId
      }).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.$refs.cart.clearCart();
        this.$router.push({name: 'Manage', params: {congrats: true}});
      });
    } else {
      this.$get(`${this.$apiEndpoint()}/categories`, {}).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.categories = response.data.categories;
        this.categorySets = response.data.categorySets;
      });
    }
  }
}
</script>

<style scoped>
/* TODO: I probably shouldn't remove this entirely */
.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0 !important;;
}
.tab-item {
  margin-top: 40px;
}
.filter-chip {
  margin: 5px
}
</style>