<template>
  <div>
    <v-banner class="accent_two" v-bind="{singleLine: !this.$isMobile}">
      <v-row justify="center" >
        <v-col align="center" class="text-body-1">
          Check out our <router-link to="/samples">sample questions</router-link>!
        </v-col>
      </v-row>
    </v-banner>
    <HeroTile/>
    <ExplainerTile/>
    <WhatYouGetTile/>
    <HowItWorksTile/>
    <div class="secondary" style="padding: 50px 0">
      <v-container>
        <v-row>
          <v-spacer/>
          <v-col cols="9">
            <p class="text-h4">Sign up for our mailing list to hear about free games and new features!</p>
            <MailingListSignup/>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import HeroTile from "../components/home/HeroTile";
import ExplainerTile from "../components/home/ExplainerTile";
import WhatYouGetTile from "../components/home/WhatYouGetTile";
import HowItWorksTile from "../components/home/HowItWorksTile";
import MailingListSignup from "../components/MailingListSignup";
export default {
  name: 'Home',
  metaInfo: {
    title: 'Trivia Night - Host the perfect virtual trivia game',
    titleTemplate: null
  },
  components: {MailingListSignup, HowItWorksTile, WhatYouGetTile, ExplainerTile, HeroTile},
}
</script>
