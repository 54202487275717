<template>
  <div>
    <v-carousel v-if="this.$isMobile" hide-delimiters v-bind="{ showArrows: false }" height="100%" v-model="slide">
      <v-carousel-item>
        <v-container :style="{ maxWidth: windowWidth }">
          <v-row justify="center"><v-col class="text-h4" align="center">{{ title }}</v-col></v-row>
          <v-row justify="center"><v-col class="text-subtitle-1" align="center">Swipe to score questions</v-col></v-row>
          <v-row justify="center">
            <v-icon x-large>mdi-gesture-swipe</v-icon>
          </v-row>
          <v-row justify="space-around" class="links-block">
            <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
            <router-link :to="{ name: 'Review Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Review round</router-link>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item v-for="(question, i) in questions" :key="i">
        <v-container :style="{ maxWidth: windowWidth }">
          <v-row class="font-weight-bold text-h5"><v-col>{{ "Question #" + (i + 1) }}</v-col></v-row>
          <v-row><v-col><span class="font-weight-bold">Q: </span><span v-html="questionText(question)"></span></v-col></v-row>
          <v-row><v-col><span class="font-weight-bold">A: </span> {{ question.answers.join(", ") }}</v-col></v-row>
          <v-row class="scoring-block">
            <v-col>
              <v-card v-for="answer in Object.keys(scoreMap[i])" :key="answer" class="mobile-scoring-card">
                <v-card-title class="justify-center">{{ answer }}</v-card-title>
                <v-card-text>
                  <v-chip-group justify="space-around">
                    <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 0 ? 'error' : 'normal'" v-on:click="assignScore(i, answer, 0)">0</v-chip>
                    <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 1 ? 'warning' : 'normal'" v-on:click="assignScore(i, answer, 1)">½</v-chip>
                    <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 2 ? 'success' : 'normal'" v-on:click="assignScore(i, answer, 2)">1</v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item v-if="questions.length > 0">
        <div class="question-block">
          <v-row><v-col align="center" class="font-weight-bold text-h5">{{ title }}</v-col></v-row>
          <v-row justify="center">
              <v-btn color="accent" class="submit-button" v-on:click="submitScores">Submit scores</v-btn>
          </v-row>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-container v-if="!this.$isMobile">
      <v-row justify="space-around" class="links-block text-h5">
        <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
        <router-link :to="{ name: 'Review Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Review round</router-link>
      </v-row>
      <v-row justify="center"><v-col class="text-h3 font-weight-bold" align="center">{{ title }}</v-col></v-row>
      <div v-for="(question, i) in questions" :key="i">
        <v-row><v-col class="text-h4 font-weight-bold">{{ "Question #" + (i + 1) }}</v-col></v-row>
        <v-row><v-col class="text-h5"><span class="font-weight-bold">Q: </span><span v-html="questionText(question)"></span></v-col></v-row>
        <v-row><v-col class="text-h5"><span class="font-weight-bold">A: </span> {{ question.answers.join(", ") }}</v-col></v-row>
        <v-row class="scoring-block" justify="space-around">
          <v-card v-for="answer in Object.keys(scoreMap[i])" :key="answer" class="scoring-card">
            <v-card-title class="justify-center text-h4">{{ answer }}</v-card-title>
            <v-card-text>
              <v-chip-group justify="space-around">
                <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 0 ? 'error' : 'normal'" v-on:click="assignScore(i, answer, 0)">0</v-chip>
                <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 1 ? 'warning' : 'normal'" v-on:click="assignScore(i, answer, 1)">½</v-chip>
                <v-chip large class="scoring-chip" :color="scoreMap[i][answer] === 2 ? 'success' : 'normal'" v-on:click="assignScore(i, answer, 2)">1</v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-row>
        <v-divider class="question-divider" />
      </div>
      <div>
        <v-row justify="center">
          <v-btn color="accent" class="submit-button" v-on:click.once="submitScores">Submit scores</v-btn>
        </v-row>
      </div>
      <v-row justify="space-around" class="links-block">
        <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
        <router-link :to="{ name: 'Review Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Review round</router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ScoreCategory",
  metaInfo: {
    title: 'Score Category'
  },
  data() {
    return {
      slide: 0,
      scoreMap: [{}],
      submissions: [],
      questions: [],
      title: ''
    }
  },
  watch: {
    slide: () => {
      window.scrollTo(0, 0);
    }
  },
  computed: {
    windowHeight() {
      return window.innerHeight + 'px';
    },
    windowWidth() {
      return (window.innerWidth - 24) + 'px';
    }
  },
  methods: {
    getTotal(answers) {
      return answers.map((a, i) => this.scoreMap[i][a]).reduce((total, value) => total + value) / 2;
    },
    assignScore(question, answer, points) {
      this.scoreMap[question][answer] = points;
    },
    submitScores() {
      this.$post(`${this.$apiEndpoint()}/manage/${this.$route.params.gameId}/category/${this.$route.params.catId}`, { scores: this.scoreMap }, {}).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.$router.push({ name: 'Manage Game', params: { id: `${this.$route.params.gameId}` }});
      });
    },
    questionText(question) {
      let text = question.question.replaceAll(/\*(.*?)\*/g, '<span class="font-italic regular-span">$1</span>');
      return `<span>${text}</span>`;
    },
  },
  mounted() {
    this.$get(`${this.$apiEndpoint()}/manage/${this.$route.params.gameId}/category/${this.$route.params.catId}`, {}).then(response => {
      if (response instanceof Error) {
        return;
      }

      let answersMap = response.data?.questions.map(() => ({}));
      response.data?.submissions.forEach((set) => {
        set.answers.forEach((answer, i) => {
          answersMap[i][answer] = set.points[i];
        });
      });
      this.scoreMap = answersMap;
      this.title = response.data?.title;
      this.questions = response.data?.questions;
      this.submissions = response.data?.submissions;
    });
  }
}
</script>

<style scoped>
.question-block {
  padding: 20px;
}
.scoring-block {
  margin-top: 10px;
}
.scoring-chip {
  padding-left: 40px;
  padding-right: 40px;
}
.mobile-scoring-card {
  margin-bottom: 20px;
  width: 325px;
}
.scoring-card {
  margin: 20px;
}
.links-block {
  margin-top: 30px;
  margin-bottom: 20px;
}
.question-divider {
  margin-top: 30px;
  margin-bottom: 30px;
}
.submit-button {
  margin-top: 20px;
  margin-bottom: 50px;
  height: 80px !important;
  min-width: 200px !important;
  font-size: 4rem !important;
}
</style>