<template>
 <canvas id="fireworks-canvas" :style="{backgroundColor: 'midnightblue' }">

 </canvas>
</template>

<script>
export default {
  name: "Fireworks",
  props: ["height", "width", "words"],
  data: () => {
    return {
      fireworkString: '',
      canvas: null,
      context: null,
      fireworks: [],
      running: true,
      rect: null,
      stars: []
    }
  },
  mounted() {
    this.canvas = document.getElementById("fireworks-canvas");
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.context = this.canvas.getContext('2d');
    this.context.font = "30px Arial";
    for (let i = 0; i < 100; i++) {
      let x = Math.random() * this.canvas.width;
      let y = Math.random() * this.canvas.height;
      this.stars.push({x: x, y: y});
    }
    let parent = this;
    this.rect = this.canvas.getBoundingClientRect();
    let createRandom = function (event) {
      event.preventDefault();
      let x = event.x - parent.rect.left;
      let y = event.y - parent.rect.top;
      parent.fireworks.push(new parent.Firework(parent, x, y, parent.randomHue()))
    };
    this.canvas.addEventListener("mouseup", createRandom);
    this.canvas.addEventListener("touchend", createRandom);
    let validKeys = [32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90];
    window.addEventListener("keyup", function (key) {
      if (validKeys.includes(key.keyCode)) {
        parent.fireworkString += key.key;
      }

      if (key.key === "Enter" || parent.fireworkString.length > 20) {
        parent.fireworks.push(new parent.Firework(parent, Math.random() * parent.width / 2 + parent.width / 4, parent.height - (Math.random() * parent.height / 4), Math.random() * 360, parent.fireworkString));
        parent.fireworkString = '';
      }
    });

    let placeholders = ["INSERT ANSWER HERE", "You missed one!", "NULL", "You'll get 'em next time!"];
    if (this.words) {
      this.words.forEach((word, i) => {
        if (!word) {
          word = placeholders[Math.floor(Math.random() * placeholders.length)];
        }
        setTimeout(function () {
          parent.fireworks.push(new parent.Firework(parent, Math.random() * parent.width / 2 + parent.width / 4, parent.height - (Math.random() * parent.height / 4), Math.random() * 360, word))
        }, i * 5000);
      });
    }
    this.requestAnimFrame(this.loopMethod);
  },
  methods: {
    requestAnimFrame: function(callback) {
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(callback);
      } else if (window.webkitRequestAnimationFrame) {
        window.webkitRequestAnimationFrame(callback);
      } else if (window.mozRequestAnimationFrame) {
        window.mozRequestAnimationFrame(callback);
      } else {
        window.setTimeout(callback, 1000 / 60);
      }
    },
    loopMethod() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.stars.forEach(star => this.drawCircle(star.x, star.y, 2, "hsl(0, 100%, 100%)"))
      this.fireworks.forEach(firework => firework.draw());
      this.requestAnimFrame(this.loopMethod);
    },
    Firework(parent, x, y, hue, word) {
      this.maxCoords = 15;
      this.targetY = y - parent.canvas.height / 2;
      this.x = x;
      this.y = y;
      this.coordinates = [{x: this.x, y: this.y}];
      this.velocity = -3;
      this.hue = hue;
      this.word = word;
      this.particles = [];
      this.shapes = [
        // Heart
        function (x, xScale) {
          x = x * 2 / xScale;
          let max = (-Math.sqrt(1 - (Math.sqrt(Math.abs(x)) / Math.sqrt(2))) + 1) * -1.5 * xScale
          let min = Math.sqrt(1 - (Math.abs(x) - 1) ** 2) * (-0.5 * xScale) - (1.5 * xScale);
          return Math.random() * (max - min) + min;
        },
        // Circle
        function (x, xScale) {
          x = x / xScale;
          let min = Math.sqrt(1 - x ** 2) * xScale - xScale;
          let max = -1 * Math.sqrt(1 - x ** 2) * xScale - xScale;
          return Math.random() * (max - min) + min;
        },
        // Square
        function (x, xScale) {
          return Math.random() * -2 * xScale;
        },
        // Bowtie?
        function (x, xScale) {
          let min = -1 * xScale - Math.abs(x);
          let max = -1 * xScale + Math.abs(x);
          return Math.random() * (max - min) + min;
        }
      ];

      this.draw = function() {
        if (this.particles.length > 0) {
          this.particles = this.particles.filter(particle => particle.x > 0 && particle.x < parent.width && particle.y < parent.height);
          if (this.particles.length === 0) {
            let i = parent.fireworks.indexOf(this);
            if (i > -1) {
              parent.fireworks.splice(i, 1);
            }
            return;
          }
          this.particles.forEach(particle => particle.draw());
        } else {
          if (this.y < this.targetY) {
            this.coordinates = [];
            if (this.word) {
              this.particles = parent.getParticlesFromWord(this.word, this.x, this.y, this.hue);
            } else {
              let xScale = 3;
              let shapeFunction = this.shapes[Math.floor(Math.random() * this.shapes.length)];
              for (let i = 0; i < 500; i++) {
                let hue = this.hue + (Math.random() * 40 - 20)
                let velX = Math.random() * 2 * xScale - xScale;
                let velY = shapeFunction(velX, xScale);
                let p = new parent.Particle(parent, this.x, this.y, hue, velX, velY);
                this.particles.push(p);
              }
            }
            return;
          }
          this.y += this.velocity;
          if (this.coordinates.length >= this.maxCoords) {
            this.coordinates.pop();
          }
          this.coordinates.unshift({x: this.x, y: this.y});
          for (let i = this.coordinates.length - 1; i >= 0; i--) {
            let c = this.coordinates[i];
            let color = "hsl(" + this.hue + ", 100%, " + (50 + 3 * i) + "%)";
            parent.drawCircle(c.x, c.y, 10 - (i / 2), color);
          }
        }
      }
    },
    Particle(parent, x, y, hue, velX, velY) {
      this.gravity = 0.05;
      this.velX = velX;
      this.velY = velY;
      this.x = x;
      this.y = y;
      this.hue = hue;
      this.draw = function() {
        parent.drawPixel(this.x, this.y, "hsl(" + this.hue + ", 100%, 50%)");
        this.x += this.velX;
        this.y += this.velY;
        this.velY += this.gravity;
      }
    },
    randomHue() {
      return Math.floor(Math.random() * 360);
    },
    drawCircle(x, y, radius, color) {
      this.context.beginPath();
      this.context.arc(x, y, radius, 0, 2 * Math.PI, false);
      this.context.fillStyle = color;
      this.context.fill();
    },
    drawPixel(x, y, color) {
      this.context.fillStyle = color;
      this.context.fillRect(x, y, 4, 4);
    },
    writeWord(x, y, word, color) {
      this.context.fillStyle = color;
      this.context.fillText(word, x, y);
    },
    getParticlesFromWord(word, origX, origY, hue) {
      this.context.fillStyle = "white";
      this.context.fillText(word, 0, 0 + 25);
      let w = Math.floor(this.context.measureText(word).width);
      let d = this.context.getImageData(0, 0, w, 30);
      this.context.clearRect(0, 0, w, 30);
      let ps = [];
      for (let y = 0; y < 30; y++) {
        for (let x = 0; x < w; x++) {
          if (d.data[(y * w + x) * 4] === 255) {
            ps.push({x: x, y: y});
          }
        }
      }
      return ps.map(p => {
        let h = hue + (Math.random() * 40 - 20);
        let velX = (p.x - w / 2) / 15;
        let velY = (30 - p.y) / 30 * -2 - 3;
        let xWiggle = (Math.random() * 0.2) - 0.1;
        let yWiggle = (Math.random() * 0.2) - 0.1;
        return new this.Particle(this, origX, origY, h, velX - xWiggle, velY - yWiggle)
      });
    }
  }
}
</script>

<style scoped>

</style>