<template>
  <v-container style="padding: 0 !important;">
    <v-row v-if="timeLeft > 0" align="center" :style="{ height: this.height + 'px' }" no-gutters>
      <v-col cols="12" style="text-align: center">
        <div :style="{ fontSize: calculateFontSize + 'px', lineHeight: 1.0 }">{{ formattedTimeLeft }}</div>
      </v-col>
      <v-col cols="12" style="text-align: center">
        <v-btn @click="endTimer" class="accent">End Time</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="timeLeft <= 0" align="center" justify="center" :style="{ height: this.height + 'px' }">
      <v-col justify="center">
        <div :style="{ fontSize: calculateMessageFontSize + 'px' }" class="accent--text">Click each question to reveal answers</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sound from '../assets/clock-alarm-8761.mp3';

export default {
  name: "TimerCard",
  props: ["width", "height", "minutes"],
  data() {
    return {
      timeLeft: this.minutes * 60,
      timerInterval: null
    }
  },
  computed: {
    formattedTimeLeft() {
      const minutes = Math.floor(this.timeLeft / 60)
      let seconds = this.timeLeft % 60
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      // The output in MM:SS format
      return `${minutes}:${seconds}`
    },
    calculateFontSize() {
      return Math.sqrt(this.height * this.width / 4) * 0.75;
    },
    calculateMessageFontSize() {
      return Math.sqrt(this.height * this.width / 37) * 0.6;
    }
  },
  methods: {
    cancelTimer() {
      clearInterval(this.timerInterval);
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timeLeft -= 1;
        if (this.timeLeft === 0) {
          let audio = new Audio(sound);
          audio.play();
          this.$emit('timeUp')
          clearInterval(this.timerInterval)
        }
      }, 1000);
    },
    endTimer() {
      clearInterval(this.timerInterval);
      this.timeLeft = 0;
      this.$emit('timeUp');
    }
  },
  mounted() {
    this.startTimer();
  }
}
</script>

<style scoped>

</style>