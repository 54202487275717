<template>
  <div>
    <v-container v-if="!this.$isMobile" style="margin-top: 100px; margin-bottom: 100px">
      <v-row>
        <v-col cols="5">
          <v-img :src="require('../../assets/home/person-studying-online.png')"></v-img>
        </v-col>
        <v-spacer/>
        <v-col cols="6">
          <p class="text-h3 font-weight-bold">{{ headline }}</p>
          <p class="text-h4">{{ subline }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="this.$isMobile">
      <v-row style="margin: 40px 0">
        <v-col>
          <p class="text-h5 font-weight-bold">{{ headline }}</p>
          <p class="text-subtitle-1">{{ subline }}</p>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-img :src="require('../../assets/home/person-studying-online.png')"></v-img>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ExplainerTile",
  data: () => {
    return {
      // headline: "With over 2500 expertly-written questions in 300 different categories, the fun never has to stop.",
      headline: "With hundreds of expertly-written questions and new ones released every week, the fun never has to stop.",
      subline: "Use our tools to turn yourself into a knowledgeable MC or hire one of our qualified pros to handle hosting duties for you. Perfect for corporate events!"
    }
  }
}
</script>

<style scoped>

</style>