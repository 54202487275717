const Utils = {
    install(Vue) {
        // Get width, then listen for changes
        const properties = Vue.observable({ isMobile: (window.innerWidth < 650 && window.innerWidth !== 0), isMobileOrTablet: (window.innerWidth < 900 && window.innerWidth !== 0), isLargeScreen: (window.innerWidth > 1440 && window.innerWidth !== 0)});
        Object.defineProperty(Vue.prototype, '$isMobile', {
            get () {
                return properties.isMobile;
            },
            set (value) {
                properties.isMobile = value;
            }
        });
        Object.defineProperty(Vue.prototype, '$isMobileOrTablet', {
            get () {
                return properties.isMobileOrTablet;
            },
            set (value) {
                properties.isMobileOrTablet = value;
            }
        });
        Object.defineProperty(Vue.prototype, '$isLargeScreen', {
            get () {
                return properties.isLargeScreen;
            },
            set (value) {
                properties.isLargeScreen = value;
            }
        });
        window.addEventListener("resize", () => {
            Vue.prototype.$isMobile = window.innerWidth < 650 && window.innerWidth !== 0;
            Vue.prototype.$isMobileOrTablet = window.innerWidth < 900 && window.innerWidth !== 0;
            Vue.prototype.$isLargeScreen = window.innerWidth > 1440 && window.innerWidth !== 0;
        });

        Vue.prototype.$loggedIn = function () {
            let token = localStorage.getItem("token");
            return token && token.length > 0;
        }

        Vue.prototype.$apiEndpoint = function () {
            if (process.env.NODE_ENV === "development") {
                return "https://trivia-night-server.fly.dev";
                // return "http://0.0.0.0:8081";
                // return "http://192.168.0.100:8081";
            } else {
                // TODO: Switch to api.trivianight.fun
                return "https://trivia-night-server.fly.dev";
                // return "https://trivia-night-server.herokuapp.com";
            }
        }

        Vue.prototype.$wsEndpoint = function() {
            if (process.env.NODE_ENV === "development") {
                // return "ws://0.0.0.0:8082";
                return "wss://trivia-night-server.fly.dev";
            } else {
                return "wss://trivia-night-server.fly.dev";
            }
        }

        Vue.prototype.$decodeJwt = function(token) {
            if (!token) {
                token = localStorage.token;
            }
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }
    }
}

export default Utils;