<template>
  <v-card class="category-set-card">
    <v-card-title>{{ set.name }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <div v-for="(cat, i) in set.categoryDetails" :key="i" class="text-body-1">
            {{ `${cat.title} (${cat.difficulty})`}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions style="padding-left: 16px; padding-bottom: 15px;">
      <v-btn v-on:click="addToCart" color="primary" outlined>Add to game</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CategorySetCard",
  props: ["set"],
  methods: {
    addToCart() {
      this.$emit('addToCart');
    }
  }
}
</script>

<style scoped>
.category-set-card {
  margin: 15px 10px;
}
</style>