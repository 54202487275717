<template>
  <v-container style="margin-top: 50px">
    <v-row v-if="destination" style="margin-bottom: 20px">
      <v-col class="text-h4 primary--text font-weight-bold">
      Not so fast! Log in or sign up and we'll send you back on your way.
      </v-col>
    </v-row>
    <v-row v-if="!signingIn">
      <v-spacer/>
      <v-col :cols="this.$isMobile ? 10 : 6" style="max-width: 400px">
        <v-form ref="form" v-model="valid" lazy-validation >
          <v-text-field v-model="email" label="Email" required :rules="emailRules" id="email-field"></v-text-field>
          <v-text-field v-model="password" label="Password" required type="password" :rules="passwordRules" id="password-field"></v-text-field>
          <v-text-field v-model="confirmPassword" v-if="registering" label="Confirm password" type="password" required :rules="confirmRules"></v-text-field>
          <v-btn style="margin: 20px 0;" @click="submit" color="accent">{{ registering ? "Register" : "Log in"}}</v-btn>
          <div><a v-on:click="registering = !registering" class="toggle-message">{{ toggleMessage }}</a></div>
          <div style="margin: 20px 0">
            <span>Or sign in with Google:</span>

            <div style="margin: 20px 0" id="google-signin"></div>
          </div>
        </v-form>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="signingIn">
      <v-spacer/>
      <v-col :cols="6" align="center">
        <v-progress-circular indeterminate color="accent" size="200" width="10"/>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginForm",
  props: ["destination", "registering"],
  data: () => ({
    valid: true,
    email: '',
    password: '',
    confirmPassword: '',
    googleToken: '',
    signingIn: false
  }),
  computed: {
    emailRules() {
      const rules = [];

      rules.push(v => !!v || 'Email is required');
      rules.push(v => (v || '').includes('@') || 'A valid email address is required')
      return rules;
    },
    passwordRules() {
      const rules = [];

      rules.push(v => !!v || 'Password is required');
      rules.push(v => (v || '').length >= 8 || 'A minimum of 8 characters is allowed')
      rules.push(v => (v || '').length <= 128 || 'A maximum of 128 characters is allowed')
      return rules;
    },
    confirmRules() {
      const rules = [];

      rules.push(v => !!v || 'Confirm password is required');
      rules.push(v => v === this.password || 'Confirm password must match password');
      return rules;
    },
    toggleMessage() {
      if (this.registering) {
        return "Already have an account? Sign in."
      } else {
        return "Don't have an account? Sign up."
      }
    },
    buttonWidth() {
      return document.getElementById('email-field').clientWidth
    }
  },
  methods: {
    submit() {
      this.email = document.getElementById('email-field').value;
      this.password = document.getElementById('password-field').value;
      if (this.$refs.form.validate()) {
        let postData = {
          email: this.email,
          password: this.password
        }
        this.signingIn = true;
        let _this = this;
        if (this.registering) {
          this.$post(`${this.$apiEndpoint()}/signup`, postData).then(response => {
            if (response instanceof Error) {
              // TODO: Handle errors
              return;
            }

            this.signingIn = false;
            localStorage.setItem("token", response.data.token)
            if (_this.destination) {
              this.$router.push(_this.destination);
            } else {
              this.$router.push("/")
            }
          });
        } else {
          this.$post(`${this.$apiEndpoint()}/login`, postData).then(response => {
            if (response instanceof Error) {
              // TODO: Handle errors
              return;
            }

            this.signingIn = false;
            localStorage.setItem("token", response.data.token)
            if (_this.destination) {
              this.$router.push(_this.destination);
            } else {
              this.$router.push("/")
            }
          });
        }
      }
    }
  },
  mounted() {
    this.Google().then(google => {
      let signinDiv = document.getElementById("google-signin");
      if (signinDiv) {
        google.accounts.id.renderButton(
            signinDiv,
            {
              theme: "filled_blue",
              size: "large",
              width: this.buttonWidth
            }
        )
      }
    });
    addEventListener('googleSignin', (event) => {
      this.signingIn = true;
      this.$post(`${this.$apiEndpoint()}/google`, {
        credential: event.credential
      }).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.signingIn = false;
        localStorage.setItem("token", response.data.token);
        let _this = this;
        if (_this.destination) {
          this.$router.push(_this.destination);
        } else {
          this.$router.push("/")
        }
      });
    })
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
.toggle-message {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}
</style>