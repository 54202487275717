<template>
  <div>
    <v-container v-if="!this.$isMobile">
      <v-row style="min-height: 220px">
        <v-col>
          <ul class="text-h5" style="line-height: 2">
            <li v-for="line in lines" :key="line">{{ line }}</li>
          </ul>
        </v-col>
        <!--<v-col cols="4">
          <v-img :src="require('../../assets/home/person-studying-online.png')" eager></v-img>
        </v-col>-->
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="advance" class="primary">{{ last ? "Restart" : "Next" }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="this.$isMobile">
      <!--<v-row>
        <v-col>
          <v-img :src="require('../../assets/home/person-studying-online.png')" eager></v-img>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <ul class="text-body-1" style="line-height: 1.5;">
            <li v-for="line in lines" :key="line">{{ line }}</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HowItWorksCard",
  props: ["lines", "image", "last"],
  methods: {
    advance() {
      this.$emit('advance');
    }
  }
}
</script>

<style scoped>

</style>