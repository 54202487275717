const Metered = {
    install(Vue) {
        let promise = null;
        Vue.prototype.Metered = function () {
            if (promise) {
                return promise;
            } else {
                promise = new Promise(function (resolve, reject) {
                    if (typeof document === "undefined") {
                        console.warn("No document defined.");
                    }
                    var el = document.createElement("script");
                    el.type = "text/javascript";
                    el.async = true;
                    el.defer = true;
                    el.src = "https://cdn.metered.ca/sdk/video/1.4.5/sdk.min.js"
                    // el.src = "https://cdn.metered.ca/sdk/frame/1.4.3/sdk-frame.min.js"

                    el.onerror = function () {
                        reject();
                    }
                    el.onabort = function () {
                        reject();
                    }
                    el.onload = function () {
                        resolve(window.Metered);
                    }
                    document.head.appendChild(el);
                });
                return promise;
            }
        }
    }
}

export default Metered;