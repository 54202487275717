<template>
  <div>
    <v-carousel v-if="this.$isMobile" hide-delimiters v-bind="{ showArrows: false }">
      <v-carousel-item>
        <v-container :style="{ maxWidth: windowWidth }">
          <v-row justify="center"><v-col class="text-h4" align="center">{{ title }}</v-col></v-row>
          <v-row justify="center"><v-col class="text-subtitle-1" align="center">Swipe to review questions</v-col></v-row>
          <v-row justify="center">
            <v-icon x-large>{{ icons.mdiGestureSwipe }}</v-icon>
          </v-row>
          <v-row justify="space-around" class="links-block">
            <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
            <router-link :to="{ name: 'Score Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Score round</router-link>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item v-for="(question, i) in questions" :key="i" :style="{ minHeight: windowHeight }">
        <v-container :style="{ maxWidth: windowWidth }">
          <v-row><v-col class="font-weight-bold text-h5">{{ "Question #" + (i + 1) }}</v-col></v-row>
          <v-row><v-col><span class="font-weight-bold">Q: </span><span v-html="questionText(question)"></span></v-col></v-row>
          <v-row><v-col><span class="font-weight-bold">A: </span> {{ question.answers.join(", ") }}</v-col></v-row>
          <v-row v-if="question.fact"><v-col><span class="font-weight-bold">Additional fact: </span><span v-html="questionFact(question)"></span></v-col></v-row>
          <v-row justify="space-between" class="links-block">
            <v-col>
              <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
            </v-col>
            <v-col>
              <router-link :to="{ name: 'Score Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Score round</router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
    <v-container v-if="!this.$isMobile">
      <v-row justify="space-around" class="links-block text-h5">
        <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
        <router-link :to="{ name: 'Score Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Score round</router-link>
      </v-row>
      <v-row justify="center" class="text-h3 font-weight-bold">{{ title }}</v-row>
      <v-card v-for="(question, i) in questions" :key="i" class="question-card">
        <div class="question-block">
          <v-row class="text-h4 font-weight-bold"><v-col>{{ "Question #" + (i + 1) }}</v-col></v-row>
          <v-row><v-col class="text-h5"><span style="font-weight: bold">Q: </span><span v-html="questionText(question)"></span></v-col></v-row>
          <v-row><v-col class="text-h5"><span style="font-weight: bold">A: </span> {{ question.answers.join(", ") }}</v-col></v-row>
          <v-row v-if="question.fact"><v-col class="text-h5"><span style="font-weight: bold">Additional fact: </span><span v-html="questionFact(question)"></span></v-col></v-row>
        </div>
      </v-card>
      <v-row justify="space-around" class="links-block">
        <router-link :to="{ name: 'Manage Game', params: { id: `${$route.params.gameId}` }}">Back to Manage</router-link>
        <router-link :to="{ name: 'Score Category', params: { gameId: `${$route.params.gameId}`, catId: `${$route.params.catId}` }}">Score round</router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mdiGestureSwipe } from '@mdi/js'
export default {
  name: "ReviewCategory",
  metaInfo: {
    title: 'Review Category'
  },
  data() {
    return {
      questions: [],
      title: '',
      icons: {
        mdiGestureSwipe
      }
    }
  },
  computed: {
    windowHeight() {
      return window.innerHeight + 'px';
    },
    windowWidth() {
      return (window.innerWidth - 24) + 'px';
    }
  },
  methods: {
    questionText(question) {
      let text = question.question.replaceAll(/\*(.*?)\*/g, '<span class="font-italic regular-span">$1</span>');
      return `<span>${text}</span>`;
    },
    questionFact(question) {
      let text = question.fact.replaceAll(/\*(.*?)\*/g, '<span class="font-italic regular-span">$1</span>');
      return `<span>${text}</span>`;
    },
  },
  mounted() {
    this.$get(`${this.$apiEndpoint()}/manage/${this.$route.params.gameId}/category/${this.$route.params.catId}`, {}).then(response => {
      if (response instanceof Error) {
        return;
      }

      this.title = response.data?.title;
      this.questions = response.data?.questions;
    });
  }
}
</script>

<style scoped>
.question-block {
  padding: 20px;
}
.question-card {
  margin: 30px;
}
.links-block {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>