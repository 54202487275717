const Video = {
    install(Vue) {
        let videoPromise = null;
        Vue.prototype.Video = function () {
            if (videoPromise) {
                return videoPromise;
            } else {
                videoPromise = new Promise(function (resolve, reject) {
                    if (typeof document === "undefined") {
                        console.warn("No document defined.");
                    }
                    var el = document.createElement("script");
                    el.type = "text/javascript";
                    el.async = true;
                    el.defer = true;
                    el.src = "https://vjs.zencdn.net/7.17.0/video.min.js"

                    el.onerror = function () {
                        reject();
                    }
                    el.onabort = function () {
                        reject();
                    }
                    el.onload = function () {
                        var css = document.createElement("link");
                        css.rel = "stylesheet";
                        css.href = "https://vjs.zencdn.net/7.17.0/video-js.css";
                        css.onerror = function () {
                            reject();
                        }
                        css.onabort = function () {
                            reject();
                        }
                        css.onload = function () {
                            resolve(window.videojs);
                        }
                        document.head.appendChild(css);
                    }
                    document.head.appendChild(el);
                });
                return videoPromise;
            }
        }
    }
}

export default Video;