<template>
    <v-container style="margin-top: 50px">
      <v-row>
        <v-col cols="6" offset="3" v-if="!loading">
          <v-alert dense text outlined type="error" color="accent" style="margin-bottom: 20px" v-if="error">{{ errorMessage }}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="name" label="Team Name" required :rules="nameRules"></v-text-field>
            <v-text-field v-model="code" label="Game Code (case sensitive)" required :rules="codeRules"></v-text-field>
            <v-btn style="margin-top: 20px;" @click="joinGame" color="accent">Join!</v-btn>
          </v-form>
        </v-col>
        <v-col cols="6" offset="3" v-if="loading">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-divider style="margin: 10px 0"></v-divider>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: "JoinGame",
  metaInfo: {
    title: 'Join Game'
  },
  mounted() {
    this.name = localStorage.getItem("team_name");
    if (this.$route.params.id) {
      this.code = this.$route.params.id;
    } else {
      this.code = localStorage.getItem("game_code");
    }
  },
  data: () => ({
    valid: true,
    error: false,
    errorMessage: '',
    loading: false,
    name: '',
    code: '',
    nameRules: [
      v => !!v || 'Team Name is required'
    ],
    codeRules: [
      v => !!v || 'Game Code is required'
    ],
  }),
  methods: {
    joinGame: function () {
      if (this.$refs.form.validate()) {
        this.error = false;
        this.loading = true;
        this.$post(`${this.$apiEndpoint()}/join`, {
          name: this.name,
          game: this.code
        }, {}).then(response => {
          this.loading = false;
          if (response instanceof Error) {
            this.errorMessage = (response.response.data) ? response.response.data : "Uh oh! Something went wrong. Reload the page and try again."
            this.error = true;
            return;
          }

          localStorage.setItem("team_name", response.data.name)
          localStorage.setItem("game_code", response.data.game)
          this.$router.push({ path: '/play' })
        });
      }
    }
  }
}
</script>

<style scoped>

</style>