<template>
 <Fireworks :height="height" :width="width"></Fireworks>
</template>

<script>
import Fireworks from "../components/Fireworks";

export default {
  name: "FireworksPage",
  components: {Fireworks},
  computed: {
    height() {
      return window.innerHeight - 64;
    },
    width() {
      return window.innerWidth;
    }
  }
}
</script>

<style scoped>

</style>