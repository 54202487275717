<template>
  <v-container fluid class="fill-height" :style="{height: innerHeight, alignItems: 'start'}">
    <v-row v-if="!flags.joined" class="fill-height" align="center">
      <v-spacer/>
      <v-col cols="6">
        <v-card :loading="flags.loading">
          <template slot="progress">
            <v-progress-linear color="primary" indeterminate />
          </template>
          <v-card-text align="center">
            <v-form v-on:submit="joinGame">
              <v-text-field v-model="name" label="What's your name?" ref="nameInput"/>
              <v-btn color="accent" v-on:click="joinGame">Join</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="flags.joined" style="height: calc(100% - 50px)">
      <v-navigation-drawer hide-overlay v-model="flags.showChat" :class="this.$isLargeScreen ? 'col-3' : ''" v-show="flags.showChat" :fixed="!this.$isLargeScreen" :style="{width: !this.$isLargeScreen ? '400px' : '', paddingTop: 0, paddingBottom: 0, height: !this.$isLargeScreen ? 'calc(100% - 66px)' : ''}">
        <iframe v-if="gameMeeting._getChatRoomURL()" :src="gameMeeting._getChatRoomURL() + '?username=' + (isHost ? 'Host' : name)" class="fill-height" style="width: 100%"/>
      </v-navigation-drawer>
      <v-navigation-drawer hide-overlay v-model="flags.showDebug" v-show="flags.showDebug" fixed :style="{width: '400px', paddingTop: 0, paddingBottom: 0, height: 'calc(100% - 66px)'}">
        <v-textarea :value="errors.join('\n')" class="fill-height" style="width: 100%" height="100%" no-resize readonly :rows="debugRows" />
      </v-navigation-drawer>
      <ObservableCol v-if="!this.$isMobileOrTablet" :cols="columnWidths.game" style="display: flex; flex-wrap: wrap; align-content: space-around; justify-content: space-around; border: black solid; position: relative;" v-on:resize="(dims) => { dimensions.game.width = dims.width; dimensions.game.height = dims.height }">
        <div style="width: 100%; height: 60%; display: flex; align-content: center; justify-content: center">
          <VideoContainer v-if="hostId" :data="participantData[hostId]" :host="true" :active="hostId === activeId" :isSelf="hostId === uuid" :maxHeight="hostVideoDims.height" :maxWidth="hostVideoDims.width"/>
        </div>
        <div style="width: 100%; height: 40%; display: flex; flex-wrap: wrap; align-content: space-around; justify-content: space-around">
          <VideoContainer v-for="id in filteredGameParticipants" v-bind:key="id" :data="participantData[id]" :host="false" :active="id === activeId" :isSelf="id === uuid" :maxHeight="gameVideoDims.height" :maxWidth="gameVideoDims.width"/>
        </div>
        <v-pagination v-model="page" :length="pages" v-show="pages > 1" style="position: absolute; bottom: 5px"/>
      </ObservableCol>
      <ObservableCol v-if="!this.$isMobileOrTablet" :cols="columnWidths.room" style="display: flex; flex-wrap: wrap; align-content: space-around; justify-content: space-around; border: black solid;" class="fill-height" v-on:resize="(dims) => { dimensions.room.width = dims.width; dimensions.room.height = dims.height }">
        <div v-if="roomParticipants.length > 0" :style="{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-around', alignContent: 'space-around', flexWrap: 'wrap' }">
          <VideoContainer v-for="id in roomParticipants" v-bind:key="id" :data="participantData[id]" :active="id === activeId" :isSelf="id === uuid" :maxHeight="roomVideoDims.height" :maxWidth="roomVideoDims.width"/>
        </div>
        <div v-else style="width: 100%; height: 100%; overflow-y: scroll">
          <RoomButton v-for="room in roomNames" v-bind:key="room" :data="roomData[room]" :name="room" v-on:join="joinRoom(room)"/>
        </div>
      </ObservableCol>
      <v-slide-x-transition hide-on-leave>
        <v-col :cols="columnWidths.captain" v-if="flags.isCaptain && !this.$isMobileOrTablet" style="width: 100%; height: 100%; overflow-y: scroll; border: black solid">
          <RoundForm v-on:nameRoom="nameRoom" :teamName="roomData[currentRoomId] ? roomData[currentRoomId].name : null" />
        </v-col>
      </v-slide-x-transition>
      <ObservableCol cols="12"  v-on:resize="(dims) => { dimensions.mobile.width = dims.width; dimensions.mobile.height = dims.height }" v-if="this.$isMobileOrTablet">
        <div style="width: 100%; height: 60%; display: flex; flex-wrap: wrap; align-content: space-around; justify-content: space-around">
          <VideoContainer v-if="hostId" :data="participantData[hostId]" :host="true" :active="hostId === activeId" :isSelf="hostId == uuid" :maxHeight="hostVideoDims.height / (currentRoomId ? 1 : 2)" :maxWidth="hostVideoDims.width / (currentRoomId ? 1 : 2)" />
          <VideoContainer v-if="!currentRoomId" :data="participantData[uuid]" :host="false" :active="false" :is-self="true" :maxHeight="hostVideoDims.height / 2" :maxWidth="hostVideoDims.width / 2" />
        </div>
        <div v-if="roomParticipants.length > 0" style="width: 100%; display: flex; flex-wrap: wrap; align-content: space-around; justify-content: space-around">
          <VideoContainer v-for="id in roomParticipants" v-bind:key="id" :data="participantData[id]" :active="id === activeId" :isSelf="id == uuid" :maxHeight="roomVideoDims.height" :maxWidth="roomVideoDims.width"/>
        </div>
        <div v-else style="width: 100%; height: 40%; padding-top: 15px" align="center">
          <div class="text-h5">Which room do you want to join?</div>
          <v-select :items="roomSelectItems" v-model="roomToJoin">
          </v-select>
          <div v-if="roomToJoin" style="margin-bottom: 10px;">
            {{ "Current participants: " + (!roomData[roomToJoin] || !roomData[roomToJoin].userIds || roomData[roomToJoin].userIds.length === 0 ? "None" : roomData[roomToJoin].userIds.join(', '))}}
          </div>
          <v-btn v-on:click="joinRoom(roomToJoin)">Join!</v-btn>
        </div>
      </ObservableCol>
    </v-row>
    <v-row v-if="flags.joined" justify="center" style="position: fixed; bottom: 0; width: 100%">
      <v-btn-toggle style="margin-bottom: 20px">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="toggleChat"><v-icon>{{ flags.showChat ? icons.mdiMessageOff : icons.mdiMessageText }}</v-icon></v-btn>
          </template>
          <span>Show / hide chat</span>
        </v-tooltip>
        <v-tooltip top v-if="currentRoomId || isHost">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="toggleMicrophone" :style="{backgroundColor: flags.microphoneOn ? 'green' : 'red'}"><v-icon>{{ flags.microphoneOn ? icons.mdiMicrophone : icons.mdiMicrophoneOff }}</v-icon></v-btn>
          </template>
          <span>{{ `Toggle microphone (currently: ${flags.microphoneOn ? 'ON' : 'OFF'})`}}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="toggleCamera" :style="{backgroundColor: flags.cameraOn ? 'green' : 'red'}"><v-icon>{{ flags.cameraOn ? icons.mdiCamera : icons.mdiCameraOff }}</v-icon></v-btn>
          </template>
          <span>{{ `Toggle camera (currently: ${flags.cameraOn ? 'ON' : 'OFF'})`}}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="showSettings"><v-icon>{{ icons.mdiCog }}</v-icon></v-btn>
          </template>
          <span>Show settings menu</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="startScreenShare" v-if="isHost"><v-icon>{{ icons.mdiMonitorShare }}</v-icon></v-btn>
          </template>
          <span>Start screen share</span>
        </v-tooltip>
        <v-tooltip top v-if="currentRoomId && !this.$isMobileOrTablet">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="toggleCaptainMode"><v-icon>{{ icons.mdiPirate }}</v-icon></v-btn>
          </template>
          <span>{{ `Toggle captain mode (currently: ${flags.isCaptain ? 'ON' : 'OFF'})`}}</span>
        </v-tooltip>
        <v-tooltip top v-if="currentRoomId">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="leaveRoom"><v-icon>{{ icons.mdiDoor }}</v-icon></v-btn>
          </template>
          <span>Leave room</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-on:click="toggleDebug"><v-icon>{{ icons.mdiBugOutline }}</v-icon></v-btn>
          </template>
          <span>Show / hide debug info</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-row>
<!--    <v-row v-if="error" v-html="error.join('<br/>')"></v-row>-->
    <v-dialog v-model="flags.showChatMobile" max-width="800" content-class="fill-height" width="100%">
      <v-card style="height: 100%">
        <v-card-title>Chat</v-card-title>
        <v-card-text v-if="flags.joined" style="height: calc(100% - 120px)">
          <iframe v-if="gameMeeting._getChatRoomURL()" :src="gameMeeting._getChatRoomURL() + '?username=' + name" class="fill-height" style="width: 100%"/>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" outlined @click="flags.showChatMobile = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="flags.showSettings" max-width="800px">
      <v-card>
        <v-card-title>Settings</v-card-title>
        <v-card-text>
          <v-select v-model="currentVideoDevice" v-on:change="updateVideoDevice" :prepend-icon="icons.mdiCamera" :items="videoDevices" label="Video devices" item-text="text" item-value="value"></v-select>
          <v-select v-model="currentAudioDevice" v-on:change="updateAudioDevice" :prepend-icon="icons.mdiMicrophone" :items="audioDevices" label="Audio devices"></v-select>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" outlined @click="flags.showSettings = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mdiMicrophone, mdiMicrophoneOff, mdiCamera, mdiCameraOff, mdiCog, mdiMonitorShare, mdiMessageText, mdiMessageOff, mdiPirate, mdiDoor, mdiBugOutline} from '@mdi/js';
import VideoContainer from "../components/play/VideoContainer";
import RoomButton from "../components/play/RoomButton";
import RoundForm from "../components/play/RoundForm";
import ObservableCol from "../components/play/ObservableCol";
export default {
  name: "Pub",
  components: {ObservableCol, RoundForm, RoomButton, VideoContainer},
  data () {
    return {
      icons: {
        mdiMicrophone, mdiMicrophoneOff, mdiCamera, mdiCameraOff, mdiCog, mdiMonitorShare, mdiMessageText, mdiMessageOff, mdiPirate, mdiDoor, mdiBugOutline
      },
      flags: {
        loading: false,
        joined: false,
        microphoneOn: false,
        cameraOn: false,
        showChat: this.$isLargeScreen,
        showDebug: false,
        showChatMobile: false,
        showSettings: false,
        isCaptain: false
      },
      dimensions: {
        mobile: {
          height: 0,
          width: 0
        },
        room: {
          height: 0,
          width: 0
        },
        game: {
          height: 0,
          width: 0
        }
      },
      page: 1,
      errors: [],
      roomToJoin: null,
      uuid: '',
      gameId: "1234",
      hostId: null,
      gameMeeting: null,
      roomMeeting: null,
      wsConnection: null,
      currentRoomId: null,
      name: "",
      participantData: {},
      roomParticipants: [],
      gameParticipants: [],
      roomNames: ["room1", "room2", "room3", "room4", "room5", "room6", "room7", "room8", "room9", "room10"],
      roomData: {},
      activeId: '',
      videoDevices: [],
      currentVideoDevice: null,
      audioDevices: [],
      currentAudioDevice: null,
    };
  },
  mounted() {
    this.$refs.nameInput.focus();
    this.uuid = this.makeId(12);
    this.Metered().then(metered => {
      this.metered = metered;
      this.setUpGameMeeting(metered);
      this.setUpRoomMeeting(metered);
    });
    this.setUpSockets();
  },
  computed: {
    debugRows() {
      return Math.floor(Math.max(this.dimensions.mobile.height, this.dimensions.game.height) / 29);
    },
    roomSelectItems() {
      return this.roomNames.map((v) => { return { text: (this.roomData[v] && this.roomData[v].name) ? this.roomData[v].name : ("Room " + v.substr(4)), value: v }})
    },
    isHost() {
      return this.name === 'Hosty Boy';
    },
    filteredGameParticipants() {
      // TODO: Maybe prioritize participants with video on?
      let maxVideos = Math.min(Math.floor(this.dimensions.game.width / 150) * Math.floor(0.4 * this.dimensions.game.height / 100), this.gameParticipants.length);
      let start = (this.page - 1) * maxVideos;
      return this.gameParticipants.filter((p) => p !== this.hostId).slice(start, Math.min(start + maxVideos, this.gameParticipants.length));
    },
    innerHeight() {
      return window.innerHeight + 'px';
    },
    columnWidths() {
      return {
        chat: this.$isLargeScreen ? (this.flags.showChat ? 3 : 0) : 0,
        game: this.$isLargeScreen ? (this.flags.showChat ? (this.flags.isCaptain ? 4 : 6) : (this.flags.isCaptain ? 6 : 8)) : (this.flags.isCaptain ? 6 : 8),
        room: this.$isLargeScreen ? (this.flags.showChat ? (this.flags.isCaptain ? 2 : 3) : (this.flags.isCaptain ? 2 : 4)) : (this.flags.isCaptain ? 2 : 4),
        captain: this.$isLargeScreen ? (this.flags.showChat ? 3 : 4) : 4
      }
    },
    hostVideoDims() {
      if (this.$isMobileOrTablet) {
        return { width: this.dimensions.mobile.width, height: this.dimensions.mobile.height * 0.6 };
      } else {
        return { width: this.dimensions.game.width, height: this.dimensions.game.height * 0.6 };
      }
    },
    roomVideoDims() {
      if (this.$isMobileOrTablet) {
        return this.getArrayDimensions(this.dimensions.mobile.width, this.dimensions.mobile.height * 0.4, this.roomParticipants.length);
      } else {
        return this.getArrayDimensions(this.dimensions.room.width, this.dimensions.room.height, this.roomParticipants.length);
      }
    },
    pages() {
      let maxVideos = Math.min(Math.floor(this.dimensions.game.width / 150) * Math.floor(0.4 * this.dimensions.game.height / 100), this.gameParticipants.length);
      return maxVideos ? Math.ceil(this.gameParticipants.length / maxVideos) : 0;
    },
    gameVideoDims() {
      return this.getArrayDimensions(this.dimensions.game.width, this.dimensions.game.height * 0.4, this.gameParticipants.length);
    }
  },
  methods: {
    getArrayDimensions(width, height, numVideos) {
      let maxVideos = Math.min(Math.floor(width / 150) * Math.floor(height / 100), numVideos);
      let scores = Array.from(Array(maxVideos).keys(), n => n+1).map((numRows) => {
        let numInRow = Math.ceil(maxVideos / numRows);
        let maxHeight = height / numRows;
        let maxWidth = width / numInRow;
        let w = Math.min(maxWidth, 1.5 * maxHeight);
        let h = Math.min(maxHeight, 2 * maxWidth / 3);
        return w * h * maxVideos;
      });
      let numRows = scores.indexOf(Math.max.apply(null, scores)) + 1;
      let numCols = Math.ceil(maxVideos / numRows);
      return {
        width: width / numCols,
        height: height / numRows
      };
    },
    async startScreenShare() {
      if (this.isHost && this.gameMeeting.meetingState === 'joined') {
        await this.gameMeeting.startScreenShare();
      }
    },
    updateAudioDevice(newDeviceId) {
      this.gameMeeting.chooseAudioInputDevice(newDeviceId);
      this.roomMeeting.chooseAudioInputDevice(newDeviceId);
    },
    updateVideoDevice(newDeviceId) {
      this.gameMeeting.chooseVideoInputDevice(newDeviceId);
    },
    showSettings() {
      this.flags.showSettings = true;
      this.updateDeviceLists();
    },
    toggleChat() {
      this.flags.showChat = !this.flags.showChat;
    },
    toggleDebug() {
      this.flags.showChat = false;
      this.flags.showDebug = !this.flags.showDebug;
    },
    toggleCaptainMode() {
      this.flags.isCaptain = !this.flags.isCaptain;
    },
    async toggleMicrophone() {
      if (this.flags.microphoneOn) {
        if (this.isHost && this.gameMeeting.meetingState === 'joined') {
          this.log("Sending unmute instruction");
          this.wsConnection.send(`{"instruction": "UNMUTE", "gameId": "${this.gameId}"}`);
          this.gameMeeting.muteLocalAudio();
          this.flags.microphoneOn = false;
        }
        if (this.roomMeeting.meetingState === 'joined') {
          this.log("Stopping audio...");
          this.roomMeeting.stopAudio();
          this.log("Success");
        }
      } else {
        if (this.isHost && this.gameMeeting.meetingState === 'joined') {
          this.log("Sending mute instruction");
          this.wsConnection.send(`{"instruction": "MUTE", "gameId": "${this.gameId}"}`);
          this.gameMeeting.unmuteLocalAudio();
          this.flags.microphoneOn = true;
        }
        if (this.roomMeeting.meetingState === 'joined') {
          this.log("Starting audio...");
          this.roomMeeting.startAudio().then(() => this.log("Success")).catch((result) => this.log(result));
        }
      }
    },
    async toggleCamera() {
      try {
        if (this.flags.cameraOn) {
          if (this.gameMeeting.meetingState === 'joined') {
            this.log("Stopping video...");
            this.gameMeeting.stopVideo();
            this.log("Success");
          }
        } else {
          if (this.gameMeeting.meetingState === 'joined') {
            this.log("Starting video...");
            this.gameMeeting.startVideo().then(() => this.log("Success")).catch((result) => this.log(result));
          }
        }
      } catch (e) {
        this.log("Failed with error: " + e);
      }
    },
    setUpGameMeeting(metered) {
      this.gameMeeting = new metered.Meeting();
      this.gameMeeting.on('onlineParticipants', (participants) => {
        for (let i in participants) {
          let participant = participants[i];
          let id = this.getId(participant.name);
          let name = this.getName(participant.name);
          if (this.gameParticipants.includes(id) || this.roomParticipants.includes(id)) continue;
          this.gameParticipants.push(id);
          this.$set(this.participantData, id, {
            video: null,
            audio: null,
            name: name,
            gameId: participant._id,
            roomId: null
          });
        }
      });
      this.gameMeeting.on('participantLeft', (participant) => {
        let id = this.getId(participant.name);
        // If you leave the game, leave the room too
        this.gameParticipants.splice(this.gameParticipants.indexOf(id), 1);
        this.roomParticipants.splice(this.roomParticipants.indexOf(id), 1);
        delete this.participantData[id];
      });
      this.gameMeeting.on('activeSpeaker', (activeSpeaker) => {
        this.activeId = this.getId(activeSpeaker.name);
      });
      this.gameMeeting.on('localTrackStarted', (item) => {
        if (item.type === 'video') {
          /*if (!this.participantData[this.uuid]) {
            this.gameParticipants.push(this.uuid);
            this.updateData(this.uuid, item.type, item.track);
            this.flags.cameraOn = true;
          }*/
          // This sometimes fails without the timeout
          setTimeout(() => {
            if (item.track.readyState === 'ended') {
              this.gameMeeting.stopVideo();
              this.gameMeeting.startVideo();
            } else {
              this.updateData(this.uuid, item.type, item.track);
              this.flags.cameraOn = true;
            }
          }, 500);
        }
      });
      this.gameMeeting.on('localTrackUpdated', (item) => {
        this.updateData(this.uuid, item.type, item.track);
      });
      this.gameMeeting.on('localTrackStopped', (item) => {
        this.updateData(this.uuid, item.type, null);
        // Verify that our state is synced
        if (item.type === 'video') {
          this.flags.cameraOn = false;
        } else {
          this.flags.microphoneOn = false;
        }
      });
      this.gameMeeting.on('remoteTrackStarted', (item) => {
        let id = this.getId(item.participant.name);
        if (!this.gameParticipants.includes(id) && !this.roomParticipants.includes(id)) {
          this.gameParticipants.push(id);
        }
        this.updateData(id, item.type, item.track);
      });
      this.gameMeeting.on('remoteTrackStopped', (item) => {
        let matches = Object.keys(this.participantData).filter((id) => this.participantData[id].gameId === item.participantSessionId)
        if (matches.length > 0) {
          matches.forEach((id) => this.updateData(id, item.type, null));
        }
      });
    },
    setUpRoomMeeting(metered) {
      this.roomMeeting = new metered.Meeting();
      this.roomMeeting.on('onlineParticipants', (participants) => {
        for (let i in participants) {
          let participant = participants[i];
          let id = this.getId(participant.name);
          if (this.roomParticipants.includes(id)) continue;

          this.gameParticipants.splice(this.gameParticipants.indexOf(this.getId(participant.name)), 1);
          this.roomParticipants.push(id);
          this.participantData[id].roomId = participant._id;
        }
      });
      this.roomMeeting.on('participantLeft', (participant) => {
        this.roomParticipants.splice(this.roomParticipants.indexOf(this.getId(participant.name)), 1);
        // TODO: I think this should get automatically re-added to gameParticipants from 'onlineParticipants'?
      });
      this.roomMeeting.on('activeSpeaker', (activeSpeaker) => {
        this.activeId = this.getId(activeSpeaker.name);
      });
      this.roomMeeting.on('localTrackStarted', () => {
        if (!this.roomParticipants.includes(this.uuid)) {
          this.gameParticipants.splice(this.gameParticipants.indexOf(this.uuid), 1);
          this.roomParticipants.push(this.uuid);
        }
        this.flags.microphoneOn = true;
      });
      this.roomMeeting.on('localTrackUpdated', (item) => {
        this.updateData(this.uuid, item.type, item.track);
      });
      this.roomMeeting.on('localTrackStopped', (item) => {
        this.updateData(this.uuid, item.type, null);
        // Verify that our state is synced
        if (item.type === 'video') {
          this.flags.cameraOn = false;
        } else {
          this.flags.microphoneOn = false;
        }
      });
      this.roomMeeting.on('remoteTrackStarted', (item) => {
        let id = this.getId(item.participant.name);
        if (!this.roomParticipants.includes(id)) {
          this.gameParticipants.splice(this.gameParticipants.indexOf(id), 1);
          this.roomParticipants.push(id);
        }
        this.updateData(id, item.type, item.track);
      });
      this.roomMeeting.on('remoteTrackStopped', (item) => {
        let matches = Object.keys(this.participantData).filter((id) => this.participantData[id].roomId === item.participantSessionId)
        if (matches.length > 0) {
          matches.forEach((id) => this.updateData(id, item.type, null));
        }
      });
    },
    async setUpDevices() {
      await this.updateDeviceLists();
      if (this.audioDevices.length > 0) {
        this.currentAudioDevice = this.audioDevices[0].value;
        this.gameMeeting.chooseAudioInputDevice(this.audioDevices[0].value);
        this.roomMeeting.chooseAudioInputDevice(this.audioDevices[0].value);
      }
      if (this.videoDevices.length > 0) {
        this.currentVideoDevice = this.videoDevices[0].value;
        this.gameMeeting.chooseVideoInputDevice(this.videoDevices[0].value);
      }
    },
    async updateDeviceLists() {
      this.audioDevices = (await this.gameMeeting.listAudioInputDevices()).map((i) => { return { text: i.label, value: i.deviceId }});
      this.videoDevices = (await this.gameMeeting.listVideoInputDevices()).map((i) => { return { text: i.label, value: i.deviceId }});
    },
    log(message) {
      console.log(message);
      this.errors.push(message);
    },
    async joinGame(e) {
      e.preventDefault();
      this.flags.loading = true;
      this.log("Setting up devices...");
      await this.setUpDevices().then(() => this.log("Success")).catch((result) => this.log(result));
      if (this.isHost) {
        let json = {
          instruction: "BECOME_HOST",
          gameId: this.gameId,
          userId: this.uuid
        }
        this.wsConnection.send(JSON.stringify(json));
      }
      this.log("Joining game meeting...");
      await this.gameMeeting.join({
        roomURL: 'trivianight.metered.live/open',
        name: this.uuid + '-' + this.name
      }).then(() => this.log("Success")).catch((result) => this.errors.push(result));
      try {
        if (this.isHost) {
          this.log("Starting host audio...");
          await this.gameMeeting.startAudio().then(() => this.log("Success")).catch((result) => this.log(result));
          if (!this.flags.microphoneOn) {
            await this.gameMeeting.muteLocalAudio();
          }
        }
        if (this.flags.cameraOn) {
          this.log("Starting video...");
          await this.gameMeeting.startVideo().then(() => this.log("Success")).catch((result) => this.log(result));
        }
      } catch (e) {
        this.log("Failed with error: " + e);
      }
      this.flags.loading = false;
      this.flags.joined = true;
    },
    async leaveRoom() {
      this.log("Leaving room meeting...")
      try {
        await this.roomMeeting.leaveMeeting();
        this.log("Success");
        this.setUpRoomMeeting(this.metered);
        let json = {
          instruction: "LEAVE_ROOM",
          gameId: this.gameId,
          roomId: this.currentRoomId,
          userId: this.uuid + '-' + this.name
        }
        this.currentRoomId = null;
        this.flags.isCaptain = false;
        this.wsConnection.send(JSON.stringify(json));
        this.gameParticipants = this.gameParticipants.concat(this.roomParticipants);
        this.roomParticipants = [];
      } catch (e) {
        this.log("Failed with error: " + e);
      }
    },
    nameRoom(name) {
      if (this.currentRoomId) {
        let json = {
          instruction: "NAME_ROOM",
          gameId: this.gameId,
          roomId: this.currentRoomId,
          roomName: name
        }
        this.wsConnection.send(JSON.stringify(json));
      }
    },
    async joinRoom(roomId) {
      this.log("Joining room meeting " + roomId + "...");
      await this.roomMeeting.join({
        roomURL: 'trivianight.metered.live/' + roomId,
        name: this.uuid + '-' + this.name
      }).then(() => this.log("Success")).catch((result) => this.log(result));
      this.currentRoomId = roomId;
      let json = {
        instruction: "JOIN_ROOM",
        gameId: this.gameId,
        roomId: roomId,
        userId: this.uuid + '-' + this.name
      }
      this.wsConnection.send(JSON.stringify(json));
      try {
        if (this.flags.microphoneOn) {
          this.log("Starting room audio...");
          await this.roomMeeting.startAudio().then(() => this.log("Success")).catch((result) => this.log(result));
        }
      } catch (e) {
        this.log("Failed with error: " + e);
      }
    },
    setUpSockets() {
      this.log("Setting up web socket connection...");
      try {
        this.wsConnection = new WebSocket(`${this.$wsEndpoint()}/game`);
        this.wsConnection.onopen = () => {
          this.wsConnection.send(`{"instruction": "CONNECT", "gameId": "${this.gameId}"}`);
        }
        this.wsConnection.onmessage = (rawMessage) => {
          let message = JSON.parse(rawMessage.data)
          if (message.type === "MUTE") {
            if (this.flags.microphoneOn) {
              this.log("Muting local audio");
              this.roomMeeting.muteLocalAudio();
            }
          } else if (message.type === "UNMUTE") {
            if (this.flags.microphoneOn) {
              this.log("Unmuting local audio");
              this.roomMeeting.unmuteLocalAudio();
            }
          } else if (message.type === "GET_INFO") {
            this.roomData = message.roomMap;
            this.hostId = message.host;
          }
        };
        this.log("Success");
      } catch (e) {
        this.log("Failed with error: " + e);
      }
    },
    makeId(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getId(name) {
      return name.split('-')[0];
    },
    getName(name) {
      return name.split('-').slice(1).join('-');
    },
    updateData(id, type, track) {
      let newData = {};
      if (type === 'audio') {
        newData = {
          audio: track ? new MediaStream([track]) : null,
          video: this.participantData[id] ? this.participantData[id].video : null,
          name: this.participantData[id] ? this.participantData[id].name : null,
          roomId: this.participantData[id] ? this.participantData[id].roomId : null,
          gameId: this.participantData[id] ? this.participantData[id].gameId : null,
        }
      } else {
        newData = {
          audio: this.participantData[id] ? this.participantData[id].audio : null,
          video: track ? new MediaStream([track]) : null,
          name: this.participantData[id] ? this.participantData[id].name : null,
          roomId: this.participantData[id] ? this.participantData[id].roomId : null,
          gameId: this.participantData[id] ? this.participantData[id].gameId : null,
        }
      }
      delete this.participantData[id];
      this.$set(this.participantData, id, newData);
    }
  },
  beforeDestroy() {
    this.gameMeeting.leaveMeeting();
    this.roomMeeting.leaveMeeting();
  }
}
</script>

<style scoped>
.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>