import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                // primary: "#5C6BC0",
                // primary: "#00408f",
                // primary: "#4DA1A9",
                primary: "#084C61",
                // primary: "#F1B5CB",
                // primary: "#f4d35e",
                background: "#FFFFFF",
                // secondary: "#DE6C83",
                // secondary: "#EF7B45",
                secondary: "#ACE0E5",
                // accent: "#519872",
                // accent: "#FFA630",
                accent: "#E06C9F",
                accent_two: "#FFC930"
            }
        }
    },
    icons: {
        iconfont: 'mdiSvg'
    }
});
