<template>
  <div>
    <v-expand-transition v-if="this.$isMobile">
      <v-row v-if="!isEmpty()" justify="center" style="position: fixed; width: 100%; bottom: 0; z-index: 2">
        <v-btn class="accent" style="margin-bottom: 50px;" @click="showSheet = !showSheet">View Cart</v-btn>
      </v-row>
    </v-expand-transition>
    <v-bottom-sheet v-if="this.$isMobile" v-model="showSheet" scrollable>
      <v-card>
        <v-row><v-col align="right"><v-btn text icon v-on:click="showSheet = false" large style="margin-right: 5px"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-col></v-row>
        <v-card-title>Game Categories</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>We recommend 3-5 rounds (taking roughly 1-2 hours) for an enjoyable and competitive experience.</v-row>
            <v-row v-for="(category, i) in cart" :key="i" class="cart-row">
              <v-col class="text-left" cols="8">
                <v-btn text icon v-on:click="removeFromCart(category)"><v-icon>{{ icons.mdiDelete }}</v-icon></v-btn>
                {{ category.title }}
              </v-col>
              <v-col class="text-right">1 x {{ formatter.format(4.99) }}</v-col>
            </v-row>
            <v-row v-if="gameType === 'DIY'" class="cart-row">
              <v-col class="text-left" cols="8">DIY</v-col>
              <v-col class="text-right">1 x {{ formatter.format(0) }}</v-col>
            </v-row>
            <v-row v-if="gameType === 'VIRTUAL'" class="cart-row">
              <v-col class="text-left" cols="8">Virtual Pub</v-col>
              <v-col class="text-right">1 x {{ formatter.format(19.99) }}</v-col>
            </v-row>
            <v-row v-if="gameType === 'HOSTED'" class="cart-row">
              <v-col class="text-left" cols="8">Professional Hosting</v-col>
              <v-col class="text-right">1 x {{ formatter.format(49.99 * Math.max(this.cart.length, 2)) }}</v-col>
            </v-row>
            <v-divider style="margin: 15px 0"/>
            <v-row>
              <v-col class="text-left">Subtotal (before applicable taxes)</v-col>
              <v-col class="text-right">{{ getPrice }}</v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
              <div style="border: black solid; margin: 40px 0; padding: 10px">
                <v-row >
                  <v-col class="font-weight-bold text-h5" align="center">Select Game Type</v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group v-model="gameType" hide-details mandatory class="radio-group" @change="storeGameType">
                      <v-radio label="DIY" value="DIY"></v-radio>
                      <v-radio label="DIY + Virtual Pub" value="VIRTUAL"></v-radio>
                      <v-radio label="Professional Hosting" value="HOSTED"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-divider style="margin: 20px 0"></v-divider>
                <v-row>
                  <v-col>
                    <v-slide-x-transition hide-on-leave>
                      <div v-show="gameType === 'DIY'" v-html="gameTypeDescriptions['diy']"/>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                      <div v-show="gameType === 'VIRTUAL'"><span>{{ gameTypeDescriptions['virtual'] }} </span><router-link :to="{name: 'FAQ', params: { link: 'virtual-pub-panel' }}" >Learn more</router-link></div>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                      <div v-show="gameType === 'HOSTED'" v-html="gameTypeDescriptions['hosted']"/>
                    </v-slide-x-transition>
                  </v-col>
                </v-row>
              </div>
              <v-row justify="center">
                <v-col>
                  <v-text-field label="Name your event so you can find it later" v-model="descriptor" required :rules="descriptorRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <v-btn v-on:click="checkout()" :disabled="isEmpty()" class="primary">CHECKOUT</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-card v-if="!this.$isMobile">
      <v-card-title>Game Categories</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>We recommend 3-5 rounds (taking roughly 1-2 hours) for an enjoyable and competitive experience.</v-row>
          <v-row v-for="(category, i) in cart" :key="i" class="cart-row">
            <v-col class="text-left" cols="8">
              <v-btn text icon v-on:click="removeFromCart(category)"><v-icon>{{ icons.mdiDelete }}</v-icon></v-btn>
              {{ category.title }}
            </v-col>
            <v-col class="text-right">1 x {{ formatter.format(4.99) }}</v-col>
          </v-row>
          <div v-if="cart.length > 0">
            <v-row v-if="gameType === 'DIY'" class="cart-row">
              <v-col class="text-left" cols="8">DIY</v-col>
              <v-col class="text-right">1 x {{ formatter.format(0) }}</v-col>
            </v-row>
            <v-row v-if="gameType === 'VIRTUAL'" class="cart-row">
              <v-col class="text-left" cols="8">Virtual Pub</v-col>
              <v-col class="text-right">1 x {{ formatter.format(19.99) }}</v-col>
            </v-row>
            <v-row v-if="gameType === 'HOSTED'" class="cart-row">
              <v-col class="text-left" cols="8">Professional Hosting</v-col>
              <v-col class="text-right">1 x {{ formatter.format(49.99 * Math.max(this.cart.length, 2)) }}</v-col>
            </v-row>
          </div>
          <v-divider style="margin: 15px 0"/>
          <v-row>
            <v-col class="text-left">Subtotal (before applicable taxes)</v-col>
            <v-col class="text-right">{{ getPrice }}</v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
            <div style="border: black solid; margin: 40px 0; padding: 10px">
              <v-row >
                <v-col class="font-weight-bold text-h5" align="center">Select Game Type</v-col>
              </v-row>
              <v-row v-if="!smallWindow">
                <v-col cols="5">
                  <v-radio-group v-model="gameType" mandatory class="radio-group" @change="storeGameType">
                    <v-radio label="DIY" value="DIY"></v-radio>
                    <v-radio label="DIY + Virtual Pub" value="VIRTUAL"></v-radio>
                    <v-radio label="Professional Hosting" value="HOSTED"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'DIY'" v-html="gameTypeDescriptions['diy']"/>
                  </v-slide-x-transition>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'VIRTUAL'"><span>{{ gameTypeDescriptions['virtual'] }} </span><router-link :to="{name: 'FAQ', params: { link: 'virtual-pub-panel' }}" >Learn more</router-link></div>
                  </v-slide-x-transition>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'HOSTED'" v-html="gameTypeDescriptions['hosted']"/>
                  </v-slide-x-transition>
                </v-col>
              </v-row>
              <v-row v-if="smallWindow">
                <v-col>
                  <v-radio-group v-model="gameType" hide-details mandatory class="radio-group" @change="storeGameType">
                    <v-radio label="DIY" value="DIY"></v-radio>
                    <v-radio label="DIY + Virtual Pub" value="VIRTUAL"></v-radio>
                    <v-radio label="Professional Hosting" value="HOSTED"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider v-if="smallWindow" style="margin: 20px 0"></v-divider>
              <v-row v-if="smallWindow">
                <v-col>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'DIY'" v-html="gameTypeDescriptions['diy']"/>
                  </v-slide-x-transition>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'VIRTUAL'"><span>{{ gameTypeDescriptions['virtual'] }} </span><router-link :to="{name: 'FAQ', params: { link: 'join-game-panel' }}" >Learn more</router-link></div>
                  </v-slide-x-transition>
                  <v-slide-x-transition hide-on-leave>
                    <div v-show="gameType === 'HOSTED'" v-html="gameTypeDescriptions['hosted']"/>
                  </v-slide-x-transition>
                </v-col>
              </v-row>
            </div>
            <v-row justify="center">
              <v-col>
                <v-text-field label="Name your event so you can find it later" v-model="descriptor" required :rules="descriptorRules"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn v-on:click="checkout()" :disabled="isEmpty()" class="primary">CHECKOUT</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import {mdiClose} from '@mdi/js'
import {mdiDelete} from '@mdi/js'

export default {
  name: "CartCard",
  data () {
    return {
      valid: true,
      cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
      showSheet: false,
      formatter: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
      gameType: 'virtual',
      gameTypeDescriptions: {
        'diy': 'Do it yourself with the help of our hosting tools and submission page! Perfect for in-person games, or those who want to configure their own video presentation.',
        'virtual': 'Get access to our virtual pub, where players can video chat with their team, talk trash to their opponents, and use the reaction feature to make the game more fun for everyone!',
        'hosted': 'Let one of our trivia pros handle everything for you, so you can sit back, relax, and play trivia. Also includes access to the virtual pub.'
      },
      descriptor: localStorage.getItem("descriptor") ? localStorage.getItem("descriptor") : "My Trivia Game",
      descriptorRules: [
        v => !!v || 'Event name is required'
      ],
      icons: {
        mdiClose,
        mdiDelete
      }
    }
  },
  computed: {
    getPrice() {
      if (this.cart.length === 0) {
        return this.formatter.format(0);
      }

      let price = this.cart.length * 4.99;
      if (this.gameType === 'VIRTUAL') {
        price += 19.99;
      } else if (this.gameType === 'HOSTED') {
        price += 49.99 * Math.max(2, this.cart.length);
      }
      return this.formatter.format(price)
    },
    smallWindow() {
      return window.innerWidth < 1000;
    }
  },
  methods: {
    addToCart(category) {
      if (this.cart.filter(cat => cat._id === category._id).length === 0) {
        this.cart.push(category);
        localStorage.setItem("cart", JSON.stringify(this.cart));
      }
    },
    isEmpty() {
      return this.cart.length <= 0;
    },
    addSetToCart(set) {
      set.categoryDetails.forEach(cat => this.addToCart(cat));
    },
    removeFromCart(category) {
      let i = this.cart.indexOf(category);
      this.cart.splice(i, 1);
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    clearCart() {
      localStorage.removeItem("cart");
      localStorage.removeItem("descriptor");
      localStorage.removeItem("gameType");
    },
    checkout() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("cart", JSON.stringify(this.cart));
        localStorage.setItem("descriptor", this.descriptor);
        localStorage.setItem("gameType", this.gameType);
        let data = {
          categories: this.cart,
          gameName: this.descriptor,
          gameType: this.gameType
        }
        this.$emit("checkout", data);
      }
    },
    storeGameType() {
      localStorage.setItem('gameType', this.gameType);
    }
  },
  mounted() {
    if (localStorage.getItem('gameType')) {
      this.gameType = localStorage.getItem('gameType');
    }
  }
}
</script>

<style scoped>
.radio-group {
  margin-top: 0;
  padding-top: 0;
}
</style>