<template>
  <v-card class="roomDetails">
    <v-card-title>{{ formattedName }}</v-card-title>
    <v-card-text>
      {{ "Current players: " + (!data || !data.userIds || data.userIds.length === 0 ? "None" : data.userIds.join(', '))}}
    </v-card-text>
    <v-card-actions>
      <v-btn @click="joinRoom">Join!</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RoomButton",
  props: ["data", "name"],
  computed: {
    formattedName() {
      if (this.data && this.data.name) {
        return this.data.name;
      }

      return "Room " + this.name.substr(4);
    }
  },
  methods: {
    joinRoom() {
      this.$emit('join');
    }
  },
}
</script>

<style scoped>
.roomDetails {
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>