<template>
  <v-card class="category-card" >
    <v-card-title>{{ category.title }}</v-card-title>
    <v-card-subtitle>
      <v-chip style="margin-right: 10px" x-small @click="fillSearch(category.difficulty.toLowerCase())">{{ category.difficulty }}</v-chip>
      <v-chip class="tag-chip" x-small v-for="tag in category.tags" :key="tag" @click="fillSearch(tag)">{{ tag.toUpperCase() }}</v-chip>
    </v-card-subtitle>
    <v-card-text>{{ category.subtitle }}</v-card-text>
    <v-card-actions style="padding-left: 16px; padding-bottom: 15px;">
      <v-btn v-on:click="addToCart" color="primary" outlined>Add to game</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CategoryCard",
  props: ["category"],
  methods: {
    addToCart() {
      this.$emit('addToCart');
    },
    fillSearch(tag) {
      this.$emit('filter', tag)
    }
  }
}
</script>

<style scoped>
.category-card {
  margin: 15px 10px;
}
.tag-chip {
  margin: 0 10px;
}
</style>