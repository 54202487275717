<template>
  <v-container :style="{height: this.height + 'px', width: this.width + 'px'}" fluid>
    <v-row :style="{height: (this.height / 3) + 'px'}">
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(0)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="1"></QuestionCard>
      </v-col>
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(1)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="2"></QuestionCard>
      </v-col>
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(2)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="3"></QuestionCard>
      </v-col>
    </v-row>
    <v-row :style="{height: (this.height / 3) + 'px'}">
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(3)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="4"></QuestionCard>
      </v-col>
      <v-col class="question-card">
        <TimerCard :height="calculateCellHeight" :width="calculateCellWidth" :minutes="minutes" @timeUp="shouldFlip = true" ref="timerCard"></TimerCard>
      </v-col>
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(4)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="5"></QuestionCard>
      </v-col>
    </v-row>
    <v-row :style="{height: (this.height / 3) + 'px'}">
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(5)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="6"></QuestionCard>
      </v-col>
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(6)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="7"></QuestionCard>
      </v-col>
      <v-col class="question-card">
        <QuestionCard :question="getQuestion(7)" :height="calculateCellHeight" :flippable="shouldFlip" :width="calculateCellWidth" :category="category.title" :number="8"></QuestionCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuestionCard from './QuestionCard'
import TimerCard from './TimerCard'

export default {
  name: "CategorySummaryCard",
  components: {QuestionCard, TimerCard},
  props: ["category", "height", "width"],
  data() {
    return {
      shouldFlip: false
    }
  },
  computed: {
    calculateTitleSize() {
      return Math.sqrt(this.height * this.width / this.title?.length) * 0.4;
    },
    calculateCellWidth() {
      return this.width / 3 - 30;
    },
    calculateCellHeight() {
      return this.height / 3 - 30;
    },
    minutes() {
      if (this.category.title.includes("Mystery")) {
        return 7;
      }

      return 6;
    }
  },
  methods: {
    cancelTimer() {
      this.$refs.timerCard.cancelTimer();
    },
    getQuestion: function (i) {
      return this.category?.questions[i];
    }
  }
}
</script>

<style scoped>
.question-card {
  border: black solid;
}
</style>