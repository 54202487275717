<template>
  <v-app :style="{height: innerHeight + 'px' }">
    <div v-if="this.$isMobile">
      <v-app-bar color="primary" dark >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>
     </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          absolute
          bottom
          temporary
      >
        <v-list
            nav
        >
          <v-list-item-group
              v-model="group"
              active-class="primary--text text--accent-4"
          >
            <v-list-item to="/">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>

            <v-list-item to="/join">
              <v-list-item-title>Join Game</v-list-item-title>
            </v-list-item>

            <v-list-item to="/create">
              <v-list-item-title>Create Game</v-list-item-title>
            </v-list-item>

            <v-list-item to="/faq">
              <v-list-item-title>FAQ</v-list-item-title>
            </v-list-item>

            <div v-if="!this.$loggedIn()">
              <v-list-item to="/login">
                <v-list-item-title>Login</v-list-item-title>
              </v-list-item>

              <v-list-item to="/signup">
                <v-list-item-title>Sign Up</v-list-item-title>
              </v-list-item>
            </div>

            <div v-if="this.$loggedIn()">
              <v-list-item to="/manage">
                <v-list-item-title>Manage Games</v-list-item-title>
              </v-list-item>

              <v-list-item @click="logOut()">
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

    </div>
    <div v-if="!this.$isMobile">
      <v-toolbar class="primary" dark height="100" elevation="2">
        <router-link to="/" class="inherit logo">TRIVIA NIGHT</router-link>
        <v-spacer />
        <router-link to="/join" class="inherit"><v-btn class="primary--text" depressed outlined style="background-color: white">Join Game</v-btn></router-link>
        <router-link to="/create" class="inherit"><v-btn class="primary" depressed>Create Game</v-btn></router-link>
        <router-link to="/faq" class="inherit"><v-btn class="primary" depressed>FAQ</v-btn></router-link>
        <div v-if="!this.$loggedIn()">
          <router-link to="/login" class="inherit"><v-btn class="primary" depressed outlined>Login</v-btn></router-link>
          <router-link to="/signup" class="inherit"><v-btn class="primary" depressed outlined>Sign Up</v-btn></router-link>
        </div>
        <div v-if="this.$loggedIn()">
          <router-link :to="{ name: 'Manage' }" class="inherit"><v-btn class="primary" depressed>Manage Games</v-btn></router-link>
          <v-btn class="primary" depressed outlined @click="logOut()">Log Out</v-btn>
        </div>
      </v-toolbar>
    </div>
    <v-main class="background">
      <router-view/>
    </v-main>

    <div v-if="$route.name !== 'Pub'" :style="{minHeight: $isMobile ? '100px' : '200px', width: '100%', backgroundColor: $route.name == 'Home' ? '#ACE0E5' : 'white' }"/>
    <v-footer style="padding: 20px; background-color: #F2F5EA" v-if="$route.name !== 'Pub'">
      <v-row v-if="$isMobile">
        <v-spacer/>
        <v-col>
          <div class="footer-logo primary--text">Trivia Night</div>
          <div>Copyright © 2022, <a href="https://www.danwaters.org">Dan Waters</a></div>
        </v-col>

        <v-spacer/>
        <v-col>
          <div class="align-self-center font-weight-bold text-h5" style="margin-bottom: 20px">Links</div>
          <ul>
            <li><a href="mailto:support@trivianight.fun">Contact Us</a></li>
            <li><router-link to="/join">Join Game</router-link></li>
            <li><router-link to="/login">Log In</router-link></li>
            <li><router-link to="/signup">Sign Up</router-link></li>
            <li><router-link to="/faq">FAQ</router-link></li>
            <li><router-link to="/privacy">Privacy Policy</router-link></li>
            <!--            <li><router-link to="/signup">About Us</router-link></li>-->

          </ul>
        </v-col>
        <v-spacer/>
      </v-row>
      <v-row v-if="!$isMobile">
        <v-spacer/>
        <v-col>
          <div class="footer-logo primary--text">Trivia Night</div>
          <div>Copyright © 2022, <a href="https://www.danwaters.org">Dan Waters</a></div>
        </v-col>

        <v-spacer/>
        <v-col>
          <a href="mailto:support@trivianight.fun">Contact/Support</a>
          <!-- TODO: Icons for facebook, instagram, etc.? -->
        </v-col>

        <v-spacer/>
        <v-col>
          <div class="align-self-center font-weight-bold text-h5 primary--text" style="margin-bottom: 20px">Links</div>
          <ul>
            <li><router-link to="/join">Join Game</router-link></li>
            <li><router-link to="/login">Log In</router-link></li>
            <li><router-link to="/signup">Sign Up</router-link></li>
            <li><router-link to="/faq">FAQ</router-link></li>
            <li><router-link to="/privacy">Privacy Policy</router-link></li>
<!--            <li><router-link to="/signup">About Us</router-link></li>-->

          </ul>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    title: 'Trivia Night',
    description: 'Host the perfect virtual trivia game while we do all the work',
    titleTemplate: '%s | Trivia Night'
  },

  data: () => ({
    drawer: false,
    group: null
  }),

  watch: {
    group() {
      this.drawer = false;
    }
  },

  computed: {
    innerHeight() {
      return window.innerHeight;
    }
  },

  methods: {
    logOut() {
      this.Google().then(google => {
        google.accounts.id.disableAutoSelect();
        localStorage.removeItem("token");
        this.$router.go()
      })
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
.logo {
  font-family: 'Bungee', cursive;
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 1px;
  font-size: 3rem;
  color: white !important;
}
.footer-logo {
  font-family: 'Bungee', cursive;
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 1px;
  font-size: 1.5rem;
}
.body {
  overscroll-behavior-y: none;
}
.inherit {
  text-decoration: inherit;
}
.regular-span {
  height: 0 !important;
}
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70% !important;
}
.outlined-text {
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 2px;
}
.outlined-text-mobile {
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 1px;
}
/* Define font sizes for all sizes */
@media (min-width: 900px) and (max-width: 1000px) {
  html {
    font-size: 14px !important;
  }
}
@media (min-width: 800px) and (max-width: 899px) {
  html {
    font-size: 12px !important;
  }
}
@media (min-width: 705px) and (max-width: 799px) {
  html {
    font-size: 10px !important;
  }
}
@media (min-width: 650px) and (max-width: 704px) {
  html {
    font-size: 8px !important;
  }
}
@media (min-width: 600px) and (max-width: 649px) {
  html {
    font-size: 22px !important;
  }
}
@media (min-width: 500px) and (max-width: 599px) {
  html {
    font-size: 20px !important;
  }
}
@media (min-width: 400px) and (max-width: 499px) {
  html {
    font-size: 18px !important;
  }
}
/* Define container max widths for all sizes */
@media (min-width: 860px) and (max-width: 959px) {
  .container {
    max-width: 800px;
  }
}
@media (min-width: 760px) and (max-width: 859px) {
  .container {
    max-width: 700px;
  }
}
@media (min-width: 650px) and (max-width: 759px) {
  .container {
    max-width: 600px;
  }
}
.chat-message {
  margin-top: 0 !important;
}

</style>
