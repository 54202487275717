<template>
  <div>
    <div id="game-area" :style="{width: gameAreaWidth + 'px', height: gameAreaHeight + 'px'}">
      <v-carousel hide-delimiters height="auto" v-model="slideNum" :continuous="false" show-arrows-on-hover>
        <v-carousel-item class="padded-item">
          <CategoryTitleCard :title="title" :width="gameAreaWidth" :height="gameAreaHeight" style="border: black solid"></CategoryTitleCard>
        </v-carousel-item>
        <v-carousel-item class="padded-item">
          <CategoryTitleCard :title="categoryTitle" :subtitle="categorySubtitle" :width="gameAreaWidth" :height="gameAreaHeight"></CategoryTitleCard>
        </v-carousel-item>
        <v-carousel-item v-for="i in numQuestions" :key="i" class="padded-item">
          <QuestionCard :question="getQuestion(i)" :flippable="false" :category="categoryTitle" :number="i" :width="gameAreaWidth" :height="gameAreaHeight" :seconds="categoryTitle.includes('Mystery') ? 60 : 80"></QuestionCard>
        </v-carousel-item>
        <v-carousel-item v-if="numQuestions > 0" class="padded-item">
          <CategorySummaryCard :category="this.category" :width="gameAreaWidth" :height="gameAreaHeight" ref="summaryCard"></CategorySummaryCard>
        </v-carousel-item>
        <v-carousel-item v-if="numQuestions > 0" class="padded-item">
          <AdvanceRoundCard :width="gameAreaWidth" :height="gameAreaHeight" v-on:advanceRound="advanceCategory" :final="final"></AdvanceRoundCard>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import QuestionCard from '../components/QuestionCard'
import CategorySummaryCard from '../components/CategorySummaryCard'
import CategoryTitleCard from '../components/CategoryTitleCard'
import AdvanceRoundCard from "../components/AdvanceRoundCard";

// TODO: Remove all unnecessary IDs
export default {
  name: "PresentGame",
  metaInfo: {
    title: 'Present Game'
  },
  components: {QuestionCard, CategorySummaryCard, CategoryTitleCard, AdvanceRoundCard},
  data () {
    return {
      title: null,
      category: null,
      slideNum: 0,
      final: false
    }
  },
  computed: {
    gameAreaHeight() {
      // TODO: Revisit once you decide on header. Maybe no header for presentation anyway?
      return window.innerHeight - 120 - 10; // Minus header height, minus buffer
    },
    gameAreaWidth() {
      return window.innerWidth;
    },
    numQuestions() {
      return this.category?.questions.length;
    },
    categoryTitle() {
      return this.category?.title;
    },
    categorySubtitle() {
      return this.category?.subtitle;
    }
  },
  methods: {
    advanceCategory() {
      this.$refs.summaryCard.cancelTimer();

      if (this.final) {
        this.$router.push({ name: "Manage Game", params: { id: this.$route.params.id }});
        return;
      }

      // NOTE: This is necessary to re-create the summary card
      this.category = null;
      this.title = "";
      this.$post(`${this.$apiEndpoint()}/present/${this.$route.params.id}/advance`, {}).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.title = response.data.title;
        this.category = response.data.category;
        this.final = response.data.final;
        this.slideNum = 1;
      });
    },
    getQuestion(i) {
      return this.category?.questions[i - 1];
    }
  },
  mounted () {
    // TODO: Handle error
    this.$get(`${this.$apiEndpoint()}/present/${this.$route.params.id}`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      }
    }).then(response => {
      if (response instanceof Error) {
        return;
      }
      this.title = response.data.title;
      if (this.title) {
        this.slideNum = 0;
      } else {
        this.slideNum = 1;
      }
      this.category = response.data.category;
      this.final = response.data.final;
    });
  },
  beforeDestroy() {
    if (this.$refs.summaryCard) {
      this.$refs.summaryCard.cancelTimer();
    }
  }
}
</script>

<style scoped>
.padded-item {
  padding: 10px 50px 10px 40px;
}
</style>