<template>
  <v-hover v-slot="{ hover }">
    <div v-if="data" :class="{activeSpeaker: active, hostVideo: host, videoContainer: true }" :style="{ height: getHeight, width: getWidth }">
      <video :src-object.prop.camel="data.video" v-if="data.video" muted autoplay playsinline :class="{ mirrored: isMirrored }"></video>
      <audio :src-object.prop.camel="data.audio" v-if="data.audio" autoplay></audio>
      <div v-if="!data.video" class="placeholder">
        <span class="text-h3">{{ host ? "Host" : data.name }}</span>
      </div>
      <div v-if="data.video && isSelf && hover" class="videoControls" >
        <v-btn v-on:click="flipVideo"><v-icon>{{ icons.mdiSwapHorizontal }}</v-icon></v-btn>
      </div>
    </div>
  </v-hover>
</template>

<script>
import { mdiSwapHorizontal } from '@mdi/js';
export default {
  name: "VideoContainer",
  props: ["data", "active", "host", "maxHeight", "maxWidth", "isSelf"],
  data: () => {
    return {
      isMirrored: false,
      icons: {
        mdiSwapHorizontal
      }
    }
  },
  mounted() {
    this.isMirrored = this.isSelf;
  },
  computed: {
    getWidth() {
      return (Math.min(this.maxWidth, 1.5 * this.maxHeight) - 3) + 'px';
    },
    getHeight() {
      return (Math.min(this.maxHeight, 2 * this.maxWidth / 3) - 3) + 'px';
    }
  },
  methods: {
    flipVideo() {
      this.isMirrored = !this.isMirrored;
    }
  }
}
</script>

<style scoped>
.placeholder {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: gray;
  color: white;
}
video {
  width: 100%;
  /* THIS NEEDS TO AGREE WITH THE ASPECT RATIO OF videoUi! */
  aspect-ratio: 3/2;
}
.mirrored {
  transform: rotateY(180deg) !important;
}
.hostVideo {
  order: -1;
}
.videoContainer {
  line-height: 0;
  position: relative;
}
/* Make aspect ratio of 3/2 */
.placeholder:before {
  content: '';
  display: block;
  padding-top: 66.66%;
}
.videoContainer.activeSpeaker {
  border: #E06C9F solid;
}
.videoContainer:not(.activeSpeaker) {
  border: black solid;
}
.videoControls {
  width: 100%;
  display:flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
}

</style>