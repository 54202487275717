const Calendly = {
    install(Vue) {
        let calendlyPromise = null;
        Vue.prototype.Calendly = function () {
            if (calendlyPromise) {
                return calendlyPromise;
            } else {
                calendlyPromise = new Promise(function (resolve, reject) {
                    if (typeof document === "undefined") {
                        console.warn("No document defined.");
                    }
                    var el = document.createElement("script");
                    el.type = "text/javascript";
                    el.async = true;
                    el.defer = true;
                    el.src = "https://assets.calendly.com/assets/external/widget.js";

                    el.onerror = function () {
                        reject();
                    }
                    el.onabort = function () {
                        reject();
                    }
                    el.onload = function () {
                        var css = document.createElement("link");
                        css.rel = "stylesheet";
                        css.href = "https://calendly.com/assets/external/widget.css"
                        css.onerror = function () {
                            reject();
                        }
                        css.onabort = function () {
                            reject();
                        }
                        css.onload = function () {
                            resolve(window.Calendly);
                        }
                        document.head.appendChild(css);
                    }
                    document.head.appendChild(el);
                });
                return calendlyPromise;
            }
        }
    }
}

export default Calendly;