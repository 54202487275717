<template>
  <v-container style="height: 100%">
    <v-row justify="center" style="margin-top: 20px">
      <v-alert type="info" style="width: 80%">
        Running your game with pencil and paper? Enter answers manually <router-link :to="{ name: 'HostEntry', params: { id: `${$route.params.id}` } }">here</router-link> then come back to finish scoring.
      </v-alert>
    </v-row>
    <v-row justify="center">
      <v-card class="category-card" v-for="(category, i) in categories" :key="i" elevation="2">
        <v-card-title>{{ category.categoryTitle }}</v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th>Team</th>
              <th>Score</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="team in sortedTeams(category)" :key="team">
              <td :style="category.teams[team] ? { color: 'green' } : {} ">{{ team }}</td>
              <td>{{ (team in category.scores) ? category.scores[team] / 2 : 0 }}</td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-divider/>
          <div class="text-center" style="margin-top: 20px">
            <router-link style="text-decoration: inherit" :to="{ name: 'Score Category', params: { gameId: `${$route.params.id}`, catId: category.categoryId }}"><v-btn class="category-button accent">{{ category.fullyScored ? "Edit Scores" : "Score" }}</v-btn></router-link>
            <router-link style="text-decoration: inherit" :to="{ name: 'Review Category', params: { gameId: `${$route.params.id}`, catId: category.categoryId }}"><v-btn class="category-button accent">Review</v-btn></router-link>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="category-card" elevation="2" v-if="Object.values(this.totals).length > 0">
        <v-card-title>Total</v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th>Team</th>
              <th>Score</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(team, i) in sortedKeys(totals)" :key="team">
              <td>{{ (i + 1) + ". " + team }}</td>
              <td>{{ totals[team] / 2 }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ManageGame",
  metaInfo: {
    title: 'Manage Game'
  },
  components: {},
  data () {
    return {
      gameData: null,
      totals: {},
      queryInterval: null
    }
  },
  computed: {
    categories() {
      return this.gameData?.categories;
    },
  },
  methods: {
    sortedTeams(category) {
      return Object.keys(category.teams).sort((a, b) => {
        let aScore = (a in category.scores) ? category.scores[a] : 0;
        let bScore = (b in category.scores) ? category.scores[b] : 0;
        return bScore - aScore;
      });
    },
    sortedKeys(totals) {
      return Object.keys(totals).sort((a, b) => totals[b] - totals[a]);
    },
    queryData() {
      this.$get(`${this.$apiEndpoint()}/manage/${this.$route.params.id}`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        }
      }).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.gameData = response.data;
        let totalsMap = {};
        this.categories.forEach((cat) => {
          Object.keys(cat.scores).forEach((team) => {
            if (team in totalsMap) {
              totalsMap[team] += cat.scores[team];
            } else {
              totalsMap[team] = cat.scores[team];
            }
          });
        });
        this.totals = totalsMap;
      });
    }
  },
  mounted () {
    this.queryData();
    this.queryInterval = setInterval(() => {
      this.queryData();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.queryInterval);
  }
}
</script>

<style scoped>
.category-card {
  margin: 20px;
}
.category-button {
  margin-left: 10px;
  margin-right: 10px;
}
</style>