<template>
  <v-container style="margin-top: 50px">
    <v-row justify="center" style="margin-bottom: 30px" v-if="roundNum > 0">
      <v-col align="center">
        <span class="text-h3 font-weight-bold">{{ roundTitle }}</span>
      </v-col>
    </v-row>
    <v-row v-if="submitted" justify="center" style="margin-top: 20px; margin-bottom: 20px;">
      <Fireworks :width="fireworksWidth()" :height="fireworksHeight()" :words="answers"></Fireworks>
    </v-row>
    <v-row v-if="submitted" >
      <v-col align="center">
        <div class="text-h5">Hooray! I bet you got them all right! <span v-if="roundNum < rounds">Now sit tight for a minute until it's time for the next round.</span></div>
        <v-btn @click="nextRound" color="primary" style="margin-top: 20px" v-if="roundNum < rounds">Next Round</v-btn>
      </v-col>
    </v-row>
    <v-form v-if="!submitted">
      <v-row>
        <v-col>
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel v-for="i in (answers.length)" :key="i" :class="getColor(i)">
              <v-expansion-panel-header style="line-height: 1.5">Question #{{ i }} - {{ answers[i-1] }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field label="Answer" v-model="answers[i-1]" @change="storeAnswers"></v-text-field>
                <v-textarea outlined label="Notes (will not be submitted)" v-model="notes[i-1]" @change="storeNotes"></v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="this.answers.length > 0">
        <v-col align="center">
          <v-btn @click="submitAnswers" color="primary">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Fireworks from "../components/Fireworks";
export default {
  name: "PlayGame",
  metaInfo: {
    title: 'Play Game'
  },
  components: {Fireworks},
  data: () => ({
    openPanel: 0,
    answers: [],
    notes: [],
    roundNum: 0,
    rounds: 0,
    gameCode: '',
    teamName: '',
    submitted: false,
  }),
  computed: {
    roundTitle() {
      return "Round " + this.roundNum;
    }
  },
  methods: {
    nextRound() {
      this.submitted = false;
      this.roundNum = Math.min(this.rounds, this.roundNum + 1);
      this.answers = new Array(8).fill('');
      this.notes = new Array(8).fill('');
    },
    storeAnswers() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
    },
    storeNotes() {
      localStorage.setItem("notes", JSON.stringify(this.notes));
    },
    submitAnswers() {
      localStorage.removeItem("answers");
      localStorage.removeItem("notes");
      let postData = {
        "team": this.teamName,
        "game": this.gameCode,
        "answers": this.answers
      }
      this.$post(`${this.$apiEndpoint()}/submit`, postData).then(response => {
        if (response instanceof Error) {
          // Do something
          return;
        }
        if (response.status === 201) {
          this.submitted = true;
        }
      });
    },
    getColor(i) {
      return (i % 2 === 1) ? "secondary" : "";
    },
    fireworksWidth() {
      return Math.min(window.innerWidth - 100, 1000);
    },
    fireworksHeight() {
      return Math.min(window.innerHeight - 200, 600);
    },
    getGameData() {
      this.$get(`${this.$apiEndpoint()}/game/${ this.gameCode }`).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.roundNum = response.data.currentRound;
        this.rounds = response.data.rounds;
        if (localStorage.getItem("answers")?.length > 0) {
          this.answers = JSON.parse(localStorage.getItem("answers"));
        } else {
          this.answers = new Array(8).fill('');
        }
        if (localStorage.getItem("notes")?.length > 0) {
          this.notes = JSON.parse(localStorage.getItem("notes"));
        } else {
          this.notes = new Array(8).fill('');
        }
      })
    }
  },
  created() {
    this.gameCode = localStorage.getItem("game_code");
    this.teamName = localStorage.getItem("team_name");
    if (!this.teamName || !this.gameCode) {
      this.$router.push("join")
    }
  },
  mounted() {
    this.getGameData();
  }
}
</script>