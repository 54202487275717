import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Network from "./plugins/network";
import Utils from "./plugins/utils";
import SimpleAnalytics from 'simple-analytics-vue'
import Calendly from "./plugins/calendly";
import GoogleSignin from "./plugins/google";
import Video from "./plugins/video";
import Metered from "./plugins/metered";
import VueMeta from "vue-meta";

Vue.config.productionTip = false

Vue.use(Utils)
Vue.use(Network)
Vue.use(Calendly)
Vue.use(GoogleSignin)
Vue.use(Video)
Vue.use(VueMeta)
Vue.use(Metered)
Vue.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== "production", domain: "api.trivianight.fun" });

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
