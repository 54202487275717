<template>
  <div class="secondary">
    <v-container style="padding-top: 50px; padding-bottom: 50px">
      <v-row v-if="!this.$isMobile" justify="center" class="text-h2 font-weight-bold accent--text outlined-text-mobile" style="margin: 50px">What You Get</v-row>
      <v-row v-if="this.$isMobile" justify="center" class="text-h3 font-weight-bold accent--text outlined-text-mobile">What You Get</v-row>
      <WhatYouGetCard :image="require('../../assets/home/question-mark-icon.png')" title="Quality Questions" text="Questions for all skill-levels, along with fun facts to keep everyone entertained." side="left"/>
      <WhatYouGetCard :image="require('../../assets/home/girl-with-microphone.png')" title="Professional Presenting" text="Hire one of our pros or use our tools to look like a pro yourself!" side="right"/>
      <WhatYouGetCard :image="require('../../assets/home/computer-paper.png')" title="Seamless Submitting" text="Players submit answers from their phone or computer. No sign-up necessary!" side="left" />
      <WhatYouGetCard :image="require('../../assets/home/calculator.png')" title="Simple Scoring" text="Score hundreds of answers in no time to find out who won!" side="right" />
    </v-container>
  </div>
</template>

<script>
import WhatYouGetCard from "./WhatYouGetCard";
export default {
  name: "WhatYouGetTile",
  components: {WhatYouGetCard}
}
</script>

<style scoped>

</style>