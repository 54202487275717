<template>
  <v-container :style="{height: this.height + 'px', width: this.width + 'px'}" fluid>
    <v-row :style="{height: (this.height / 2) + 'px'}" align="end" justify="center">
      <div>
        <v-btn class="accent" v-on:click="advanceRound">{{ final ? "View Scores" : "Advance Round" }}</v-btn>
      </div>
    </v-row>
    <v-row :style="{height: (this.height / 2) + 'px'}" align="start" justify="center">
      <div style="margin-top: 10px">
        {{
        final ? "NOTE: Complete scoring for all rounds before viewing scores" : "NOTE: Players will no longer be able to submit answers to previous round."
        }}
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdvanceRoundCard",
  props: ["height", "width", "final"],
  methods: {
    advanceRound() {
      this.$emit("advanceRound")
    }
  }
}
</script>

<style scoped>

</style>