let initialize = function(google, callback) {
    google.accounts.id.initialize({
        // client_id: "1081888550532-ufovop06o1dnpqbjlrqvups63vpbpohv.apps.googleusercontent.com",
        client_id: "1081888550532-sft8h6hnprf8c8l685gbjelcul72315t.apps.googleusercontent.com",
        callback: callback,
    });
    if (!localStorage.getItem("token")) {
        // TODO: Figure out a non-invasive way to use prompt
        // google.accounts.id.prompt();
    }
}
const GoogleSignin = {
    install(Vue) {
        let initPromise = new Promise(function (resolve) {
            addEventListener('googleInitialized', () => {
                resolve(window.google);
            }, {
                once: true
            })
        });
        Vue.prototype.GoogleInit = function(callback) {
            let promise = new Promise(function (resolve, reject) {
                if (typeof document === "undefined") {
                    console.warn("No document defined.");
                }
                var el = document.createElement("script");
                el.type = "text/javascript";
                el.async = true;
                el.defer = true;
                el.src = "https://accounts.google.com/gsi/client"

                el.onerror = function () {
                    reject();
                }
                el.onabort = function () {
                    reject();
                }
                el.onload = function () {
                    resolve(window.google);
                }
                document.head.appendChild(el);
            });
            promise.then(google => {
                initialize(google, callback);
                let event = new Event('googleInitialized')
                dispatchEvent(event);
            });
        }
        Vue.prototype.Google = function () {
            let googleCallback = (response) => {
                let event = new Event('googleSignin');
                event.credential = response.credential;
                dispatchEvent(event);
            }
            this.GoogleInit(googleCallback);
            return initPromise;
        }
    }
}

export default GoogleSignin;