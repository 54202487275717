import axios from "axios";

const Network = {
    // TODO: Add options if there should be anything configurable
    install(Vue) {

        Vue.prototype.$post = function (url, data, config) {
            let token = localStorage.getItem("token");
            if (token && token.length > 0) {
                if (config && config.headers) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                } else if (config) {
                    config.headers = {'Authorization': `Bearer ${token}`};
                } else {
                    config = {
                        headers: {'Authorization': `Bearer ${token}`}
                    };
                }
            }
            return axios.post(url, data, config).catch(error => {
                if (error?.response?.status === 401 || error?.response?.status === 403) {
                    this.$router.push({ name: 'Login', params: { destination: this.$route }});
                }
                return error;
            });
        }

        Vue.prototype.$get = function (url, config) {
            let token = localStorage.getItem("token");
            if (token && token.length > 0) {
                if (config && config.headers) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                } else if (config) {
                    config.headers = {'Authorization': `Bearer ${token}`};
                } else {
                    config = {
                        headers: {'Authorization': `Bearer ${token}`}
                    };
                }
            }
            return axios.get(url, config).catch(error => {
                if (error?.response?.status === 401 || error?.response?.status === 403) {
                    this.$router.push({ name: 'Login', params: { destination: this.$route }});
                }
                return error;
            });
        }
    }
}

export default Network;