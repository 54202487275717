<template>
  <v-container :style="{height: this.height + 'px', width: this.width + 'px'}" fluid>
    <v-row :style="{height: this.height / 2 + 'px', fontSize: calculateTitleSize + 'px'}" align="end" justify="center"><v-col align="center">{{ title }}</v-col></v-row>
    <v-row :style="{height: this.height / 2 + 'px', fontSize: calculateSubTitleSize + 'px'}" align="top" justify="center"><v-col align="center">{{ subtitle }}</v-col></v-row>
  </v-container>
</template>

<script>
export default {
  name: "CategoryTitleCard",
  props: ["title", "subtitle", "height", "width"],
  computed: {
    calculateTitleSize() {
      return Math.sqrt(this.height / 2 * this.width / this.title?.length) * 0.6;
    },
    calculateSubTitleSize() {
      return Math.sqrt(this.height / 2 * this.width / this.title?.length) * 0.2;
    },
  }
}
</script>

<style scoped>

</style>