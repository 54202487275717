<template>
  <div>
    <v-row v-if="!this.$isMobile" style="min-height: 300px">
      <v-spacer/>
      <v-col v-if="side === 'right'" cols="2" >
        <v-img :src="image"></v-img>
      </v-col>
      <v-col cols="6">
        <v-card flat class="secondary">
          <v-card-title class="font-weight-bold text-h4" style="word-break: normal">{{ title }}</v-card-title>
          <v-card-text class="text-h5" style="line-height: 2">
            <div>{{ text }}</div>
            <router-link v-if="title.includes('Questions')" to="/samples">Try them!</router-link>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="side === 'left'" cols="2">
        <v-img :src="image"></v-img>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="this.$isMobile" style="margin-top: 50px">
      <v-spacer/>
      <v-col cols="3" v-if="side === 'right'">
        <v-img :src="image"></v-img>
      </v-col>
      <v-col cols="7" class="font-weight-bold text-h4">{{ title }}</v-col>
      <v-col cols="3" v-if="side === 'left'" justify="center">
        <v-img :src="image"></v-img>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="this.$isMobile" style="margin-bottom: 50px">
      <v-spacer/>
      <v-col class="text-h5" cols="10">
        <div>{{ text }}</div>
        <router-link v-if="title.includes('Questions')" to="/samples">Try them!</router-link>
      </v-col>
      <v-spacer/>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "WhatYouGetCard",
  props: ["title", "text", "image", "side"]
}
</script>

<style scoped>

</style>