<template>
 <v-container style="margin-top: 50px">
   <v-row justify="center" class="text-h3 font-weight-bold">
     <v-col align="center">Sample Questions (click for answers)</v-col>
   </v-row>
   <v-row justify="center" class="text-h5" style="margin-top: 40px">
     <v-col align="center">In order from easiest to hardest from the category: <span class="font-italic font-weight-bold">&nbsp;We "Can" Do It</span></v-col>
   </v-row>
   <v-row justify="center">
     <v-col>
       <SampleQuestionsCard :width="this.$isMobile ? 350 : 700" :height="this.$isMobile ? 262 : 525"/>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import SampleQuestionsCard from "../components/SampleQuestionsCard";

export default {
  name: "Samples",
  metaInfo: {
    title: 'Sample Questions'
  },
  components: {SampleQuestionsCard},
  methods: {
  }
}
</script>

<style scoped>

</style>