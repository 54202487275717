<template>
  <v-container style="height: 100%">
    <v-row style="height: 100%; font-weight: bolder" align="center" justify="center">
      <LoginForm :destination="this.$route.params.destination"/>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  name: 'Login',
  metaInfo: {
    title: 'Log In'
  },
  components: {LoginForm},
  mounted() {
  }
}
</script>
