<template>
  <v-container class="fill-height">
    <v-row v-if="!isJoined" class="fill-height">
      <v-col cols="12" v-if="!loading" class="fill-height align-center justify-center" style="display: flex">
        <v-alert dense text outlined type="error" color="accent" style="margin-bottom: 20px" v-if="error">{{ errorMessage }}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation v-on:submit="submit">
          <v-text-field v-model="name" label="Team Name" required :rules="nameRules"></v-text-field>
          <v-btn style="margin-top: 20px;" @click="joinGame" color="accent">Set Team Name</v-btn>
        </v-form>
      </v-col>
      <v-col cols="12" v-if="loading">
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <v-divider style="margin: 10px 0"></v-divider>
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row justify="center" style="margin-bottom: 30px" v-if="isJoined && roundNum > 0">
      <v-col align="center">
        <span class="text-h3 font-weight-bold">{{ roundTitle }}</span>
      </v-col>
    </v-row>
    <v-row v-if="isJoined && submitted" >
      <v-col align="center">
        <div class="text-h5">Hooray! I bet you got them all right! <span v-if="roundNum < rounds">Now sit tight for a minute until it's time for the next round.</span></div>
        <v-btn @click="nextRound" color="primary" style="margin-top: 20px" v-if="roundNum < rounds">Next Round</v-btn>
      </v-col>
    </v-row>
    <v-form v-if="isJoined && !submitted">
      <v-row>
        <v-col>
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel v-for="i in (answers.length)" :key="i" :class="getColor(i)">
              <v-expansion-panel-header style="line-height: 1.5">Question #{{ i }} - {{ answers[i-1] }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field label="Answer" v-model="answers[i-1]" @change="storeAnswers"></v-text-field>
                <v-textarea outlined label="Notes (will not be submitted)" v-model="notes[i-1]" @change="storeNotes"></v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="this.answers.length > 0">
        <v-col align="center">
          <v-btn @click="submitAnswers" color="primary">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "RoundForm",
  props: ["teamName"],
  mounted() {
    this.name = localStorage.getItem("team_name");
    if (this.$route.params.id) {
      this.code = this.$route.params.id;
    }
    this.getGameData();
  },
  data: () => ({
    openPanel: 0,
    joined: false,
    valid: true,
    error: false,
    errorMessage: '',
    loading: false,
    name: '',
    code: '',
    nameRules: [
      v => !!v || 'Team Name is required'
    ],
    answers: [],
    notes: [],
    roundNum: 0,
    submitted: false
  }),
  computed: {
    roundTitle() {
      return "Round " + this.roundNum;
    },
    isJoined() {
      return this.joined || this.teamName;
    }
  },
  methods: {
    getColor(i) {
      return (i % 2 === 1) ? "secondary" : "";
    },
    nextRound() {
      this.submitted = false;
      this.roundNum = Math.min(this.rounds, this.roundNum + 1);
      this.answers = new Array(8).fill('');
      this.notes = new Array(8).fill('');
    },
    storeAnswers() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
    },
    storeNotes() {
      localStorage.setItem("notes", JSON.stringify(this.notes));
    },
    submitAnswers() {
      localStorage.removeItem("answers");
      localStorage.removeItem("notes");
      let postData = {
        "team": this.teamName,
        "game": this.code,
        "answers": this.answers
      }
      this.$post(`${this.$apiEndpoint()}/submit`, postData).then(response => {
        if (response instanceof Error) {
          // Do something
          return;
        }
        if (response.status === 201) {
          this.submitted = true;
        }
      });
    },
    submit(e) {
      e.preventDefault();
      this.joinGame();
    },
    joinGame () {
      if (this.$refs.form.validate()) {
        this.error = false;
        this.loading = true;
        this.$post(`${this.$apiEndpoint()}/join`, {
          name: this.name,
          game: this.code
        }, {}).then(response => {
          this.loading = false;
          if (response instanceof Error) {
            this.errorMessage = (response.response.data) ? response.response.data : "Uh oh! Something went wrong. Reload the page and try again."
            this.error = true;
            return;
          }

          localStorage.setItem("team_name", response.data.name)
          this.joined = true;
          this.$emit('nameRoom', response.data.name);
        });
      }
    },
    getGameData() {
      this.$get(`${this.$apiEndpoint()}/game/${ this.code }`).then(response => {
        if (response instanceof Error) {
          return;
        }

        this.roundNum = response.data.currentRound;
        this.rounds = response.data.rounds;
        if (localStorage.getItem("answers")?.length > 0) {
          this.answers = JSON.parse(localStorage.getItem("answers"));
        } else {
          this.answers = new Array(8).fill('');
        }
        if (localStorage.getItem("notes")?.length > 0) {
          this.notes = JSON.parse(localStorage.getItem("notes"));
        } else {
          this.notes = new Array(8).fill('');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>