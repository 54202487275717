<template>
  <div>
    <v-container v-if="!this.$isMobile" style="padding-top: 50px">
      <v-row justify="center" class="text-h2 font-weight-bold primary--text" style="margin: 50px;">
        How It Works
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-stepper non-linear v-model="step" alt-labels flat>
            <v-stepper-header style="box-shadow: none">
              <v-stepper-step editable :complete="step > 1" step="1" color="primary" edit-icon="$complete" style="padding: 24px 0">
                <span class="primary--text text-h5">Create a Game</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable :complete="step > 2" step="2" color="primary" edit-icon="$complete" style="padding: 24px 0">
                <span class="primary--text text-h5">Invite Friends</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable :complete="step > 3" step="3" color="primary" edit-icon="$complete" style="padding: 24px 0">
                <span class="primary--text text-h5">Host</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable :complete="step > 4" step="4" color="primary" edit-icon="$complete" style="padding: 24px 0">
                <span class="primary--text text-h5">See Who Won!</span>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <HowItWorksCard v-on:advance="step = 2" :lines="createLines" />
              </v-stepper-content>
              <v-stepper-content step="2">
                <HowItWorksCard v-on:advance="step = 3" :lines="inviteLines" />
              </v-stepper-content>
              <v-stepper-content step="3">
                <HowItWorksCard v-on:advance="step = 4" :lines="hostLines" />
              </v-stepper-content>
              <v-stepper-content step="4">
                <HowItWorksCard v-on:advance="step = 1" :lines="scoreLines" :last="true" />
                <!-- TODO: Make certificates for the winners? -->
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="this.$isMobile">
      <v-row justify="center" class="text-h3 font-weight-bold primary--text" style="margin: 50px;">
        <v-col align="center">How It Works</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-stepper non-linear vertical v-model="step" flat>
            <v-stepper-step editable :complete="step > 1" step="1" color="primary" edit-icon="$complete" class="remove-background">
              <span class="primary--text text-h5">Create a Game</span>
            </v-stepper-step>
            <v-stepper-content step="1">
              <HowItWorksCard v-on:advance="step = 2" :lines="createLines" />
            </v-stepper-content>

            <v-stepper-step editable :complete="step > 2" step="2" color="primary" edit-icon="$complete" class="remove-background">
              <span class="primary--text text-h5">Invite Friends</span>
            </v-stepper-step>
            <v-stepper-content step="2">
              <HowItWorksCard v-on:advance="step = 3" :lines="inviteLines" />
            </v-stepper-content>

            <v-stepper-step editable :complete="step > 3" step="3" color="primary" edit-icon="$complete" class="remove-background">
              <span class="primary--text text-h5">Host</span>
            </v-stepper-step>
            <v-stepper-content step="3">
              <HowItWorksCard v-on:advance="step = 4" :lines="hostLines" />
            </v-stepper-content>

            <v-stepper-step editable :complete="step > 4" step="4" color="primary" edit-icon="$complete" class="remove-background">
              <span class="primary--text text-h5">See Who Won!</span>
            </v-stepper-step>
            <v-stepper-content step="4">
              <HowItWorksCard v-on:advance="step = 1" :lines="scoreLines" :last="true" />
              <!-- TODO: Make certificates for the winners? -->
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HowItWorksCard from "./HowItWorksCard";
export default {
  name: "HowItWorksTile",
  components: {HowItWorksCard},
  data: () => {
    return {
      step: 1,
      createLines: [
          "Choose from hundreds of different categories, or use one of our curated variety packs",
          "Five different difficulty levels to tailor the game to your audience",
          "Each category has eight creative questions to test your knowledge",
          "Make your game as short or as long as you want"
      ],
      inviteLines: [
        "When it's time to play, send a customized link to your teams",
        "No registration necessary!",
        "Players can participate on mobile or desktop"
      ],
      hostLines: [
        "Hire one of our pros to handle everything for you or use our tools to do it yourself",
        "Our \"virtual pub\" brings the fun of in-person trivia to your home or workplace",
        "We feed you fun facts to entertain players and make you look like a trivia pro!"
      ],
      scoreLines: [
        "With our simple tools, score hundreds of answers in no time",
        "Make a mistake? It's easy to edit scores on the fly",
        "Easily see the winners of each round",
        "Display the final scores to everyone so they can see how they did"
      ]
    }
  }
}
</script>

<style scoped>
.remove-background {
  background-color: white !important;
}
</style>