import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PresentGame from '../views/PresentGame'
import CreateGame from '../views/CreateGame'
import JoinGame from '../views/JoinGame'
import PlayGame from '../views/PlayGame'
import ManageGame from '../views/ManageGame'
import ScoreCategory from '../views/ScoreCategory'
import ReviewCategory from '../views/ReviewCategory'
import Manage from '../views/Manage'
import FireworksPage from "../views/FireworksPage";
import Login from "../views/Login";
import Signup from "../views/Signup";
import Samples from "../views/Samples";
import Metered from "../views/Pub";
import Pub from "../views/Pub";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/present/:id',
    name: 'Present Game',
    component: PresentGame
  },
  {
    path: '/manage/:id',
    name: 'Manage Game',
    component: ManageGame
  },
  {
    path: '/manage/:gameId/score/:catId',
    name: 'Score Category',
    component: ScoreCategory
  },
  {
    path: '/manage/:gameId/review/:catId',
    name: 'Review Category',
    component: ReviewCategory
  },
  {
    path: '/fireworks',
    name: 'Fireworks',
    component: FireworksPage
  },
  {
    path: '/join/:id?',
    name: 'Join Game',
    component: JoinGame
  },
  {
    path: '/ohyay',
    name: 'Oh Yay Instructions',
    component: () => import('../views/OhYay.vue')
  },
  {
    path: '/play',
    name: 'Play Game',
    component: PlayGame
  },
  {
    path: '/create',
    name: 'Create Game',
    component: CreateGame
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage
  },
  {
    path: '/entry/:id',
    name: 'HostEntry',
    component: () => import('../views/HostEntry.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/Instructions.vue')
  },
  {
    path: '/samples',
    name: 'Samples',
    component: Samples
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  /*{
    path: '/open',
    name: 'Open',
    component: Open
  },*/
  {
    path: '/metered',
    name: 'Metered',
    component: Metered
  },
  {
    path: '/pub/:id',
    name: 'Pub',
    component: Pub
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const publicPages = ['Home', 'Join Game', 'Login', 'Signup', 'Play Game', 'FAQ', 'Create Game', 'Oh Yay Instructions', 'Samples', 'Privacy Policy', 'Open', 'Metered', 'Pub'];

/*function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (publicPages.includes(route.name)) {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://www.trivianight.fun')
      .map(route => `<url><loc>${route}</loc></url>`)
      .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

console.log(getRoutesXML());
 */

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token")?.length > 0;
  if (!publicPages.includes(to.name) && !loggedIn) {
    next({ name: 'Login', params: { destination: to }});
    // next();
  } else {
    next();
  }
});

export default router
