<template>
 <v-col :cols="cols" ref="column">
  <slot></slot>
 </v-col>
</template>

<script>
export default {
  name: "ObservableCol",
  props: ['cols'],
  data: () => {
    return {
      width: 0,
      height: 0,
      ro: null
    }
  },
  mounted() {
    let dims = this.$refs.column.getBoundingClientRect();
    this.width = dims.width;
    this.height = dims.height;
    this.$emit('resize', { width: this.width, height: this.height });
    this.ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          let dims = entry.contentBoxSize[0];
          this.width = dims.inlineSize;
          this.height = dims.blockSize;
          this.$emit('resize', {width: this.width, height: this.height });
        }
      }
    });
    this.ro.observe(this.$refs.column);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.column);
  }
}
</script>

<style scoped>

</style>